/* eslint-disable */
import Button from '@/shared/components/button/button.component'
import HoneycombImageComponent from '@/shared/components/honeycomb-image/honeycomb-image.component'
import InputDate from '@/shared/components/input-date/input-date.component'
import Input from '@/shared/components/input/input.component'
import LoaderComponent from '@/shared/components/loader/loader.component'
import ModalComponent from '@/shared/components/modal/modal.component'
import VerifiedIconComponent from '@/shared/components/verified-icon/verified-icon.component'
import ToastHelper from '@/shared/helpers/toast.helper'
import { User } from '@/shared/interfaces/user.interface'
import userObservable from '@/shared/observables/user.observable'
import LoginService from '@/shared/services/login.service'
import { USER_KEY } from '@/shared/services/main.service'
import UserService from '@/shared/services/user.service'
import localForageHelper from '@helpers/localforage.helper'
import localforage from 'localforage'
import { ChangeEvent, HTMLAttributes, PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import './edit-profile.page.scoped.scss'


interface Props extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {

}

export default ({ className = '', ...rest }: Props) => {
  const [user, setUser] = useState<User | null>(null)
  const [loading, setLoading] = useState(true)
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [loadingReset, setLoadingReset] = useState(false)
  const [resetPasswordModal, setResetPasswordModal] = useState(false)
  const navigate = useNavigate()
  const [t] = useTranslation('userProfilePage')
  const userService = useMemo(() => new UserService(), [])
  const loginService = useMemo(() => new LoginService(), [])
  const toast = new ToastHelper().toast
  const [edit, setEdit] = useState({
    name: '',
    email: '',
    username: '',
    bio: '',
    link: '',
    birthday: ''
  })
  const profileImgInputRef = useRef<HTMLInputElement>(null)
  const coverImgInputRef = useRef<HTMLInputElement>(null)
  const [profileImgSelectedFile, setProfileImgSelectedFile] = useState<File | null>(null)
  const [profileImg, setProfileImg] = useState<string | null>(null)
  const [selectedCoverImgFile, setCoverImgSelectedFile] = useState<File | null>(null)
  const [coverImg, setCoverImg] = useState<string | null>(null)
  const [loadingCountUsername, setLoadingCountUsername] = useState(false)
  const [loadingCountEmail, setLoadingCountEmail] = useState(false)
  const [usernameTiping, setUsernameTiping] = useState(true)
  const [emailTiping, setEmailTiping] = useState(true)
  const [usernameInUse, setUsernameInUse] = useState(false)
  const [emailInUse, setEmailInUse] = useState(false)
  const [originalUsername, setOriginalUsername] = useState('')
  const [originalEmail, setOriginalEmail] = useState('')

  const loadUser = useCallback(async (u: string) => {
    try {
      const user = await userService.getUserInfoByUsername(u)
      setUser(user)
      setRegisterValues(user)
      setOriginalUsername(user?.username)
      setOriginalEmail(user?.email)
    } catch (error) {
      to404()
    } finally {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userService, loading])

  const to404 = () => {
    navigate('/error/not-found')
  }

  const setRegisterValues = (user: any) => {
    const updatedRegister = {
      name: user?.name || '',
      email: user?.email || '',
      username: user?.username || '',
      bio: user?.bio || '',
      link: user?.link || '',
      birthday: user?.birthday
    }

    setEdit(updatedRegister)
  }

  const handleProfileFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.files && event.target.files.length > 0) {
      const file = event.target.files[0]
      setProfileImgSelectedFile(file)
      const imageUrl = URL.createObjectURL(file)
      setProfileImg(imageUrl)
    }
  }

  const handleCoverFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.files && event.target.files.length > 0) {
      const file = event.target.files[0]
      setCoverImgSelectedFile(file)
      const imageUrl = URL.createObjectURL(file)
      setCoverImg(imageUrl)
    }
  }

  const handleProfileClick = () => {
    if (profileImgInputRef.current) {
      profileImgInputRef.current.click()
    }
  }

  const handleCoverClick = () => {
    if (coverImgInputRef.current) {
      coverImgInputRef.current.click()
    }
  }

  useEffect(() => {
    localforage.getItem<any>(USER_KEY).then((u) => {
      if (u?.userInfo?.username && u?.userInfo?.username !== user?.username) {
        loadUser(u?.userInfo?.username)
      } else if (!u?.userInfo?.username) {
        to404()
      }
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleResetPasswordSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!loadingReset) {
      setLoadingReset(true)
      if (user) {
        await loginService.postResetPassword(user?.email).then(() => {
          toast.success(t('toast.successPasswordEmail'), {
            closeButton: true,
            className: 'full',
            position: `${window.innerWidth > 991 ? 'top' : 'bottom'} center`,
            notOverClick: true,
            immediately: true,
          })
          setResetPasswordModal(false)
        }).catch(() => {
          toast.error(t('toast.errorPasswordEmail'), {
            closeButton: true,
            className: 'full',
            position: `${window.innerWidth > 991 ? 'top' : 'bottom'} center`,
            notOverClick: true,
            immediately: true,
          })
        }).finally(() => setLoadingReset(false))
      }
    }
  }

  useEffect(() => {
    if (edit?.email?.length && !loadingCountEmail && !emailTiping) {
      setLoadingCountEmail(true)
      userService.countField('email', edit?.email).then((r) => {
        if (user?.email !== edit?.email) {
          setEmailInUse(r?.count > 0)
        }
        if (originalEmail === edit?.email) {
          setEmailInUse(false)
        }
      })
        .finally(() => setLoadingCountEmail(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit?.email, userService, emailTiping])

  useEffect(() => {
    if (edit?.username?.length && !loadingCountUsername && !usernameTiping) {
      setLoadingCountUsername(true)
      userService.countField('username', edit?.username).then((r) => {
        if (user?.username !== edit?.username) {
          setUsernameInUse(r?.count > 0)
        }
        if (originalUsername === edit?.username) {
          setUsernameInUse(false)
        }
      })
        .finally(() => setLoadingCountUsername(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit?.username, userService, usernameTiping])

  const validateAllValidation = () => {
    return !usernameInUse && !emailInUse
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!loadingSubmit) {
      setLoadingSubmit(true)
      try {
        if (user) {
          await userService.patchUserInfoById(user.id, edit)
          const localUser: any = await localForageHelper.getItem(USER_KEY)
          await localForageHelper.setItem(USER_KEY, { ...localUser, userInfo: { ...localUser?.userInfo, ...edit } })
          const newUser = {
            ...user,
            ...edit,
            is_verified: user?.is_verified && user?.username === edit?.username ? 1 : 0
          }
          userObservable.next(newUser)
          setUser(newUser)
        }
        if (profileImgSelectedFile) {
          const uploadProfileImageUrl = await userService.getUploadPhotoUrlSigned('profile')
          await fetch(uploadProfileImageUrl.url, {
            method: "PUT",
            body: profileImgSelectedFile,
            headers: {
              "Content-Type": profileImgSelectedFile.type,
              "Cache-Control": "no-cache, no-store, must-revalidate",
            },
          })
        }

        if (selectedCoverImgFile) {
          const uploadCoverImageUrl = await userService.getUploadPhotoUrlSigned('cover')
          await fetch(uploadCoverImageUrl.url, {
            method: "PUT",
            body: selectedCoverImgFile,
            headers: {
              "Content-Type": selectedCoverImgFile.type,
              "Cache-Control": "no-cache, no-store, must-revalidate",
            },
          })
        }
        toast.success(t('toast.success'), {
          closeButton: true,
          className: 'full',
          position: `${window.innerWidth > 991 ? 'top' : 'bottom'} center`,
          notOverClick: true,
          immediately: true,
        })
      } catch (error: any) {
        toast.error(t('toast.patchError'), {
          closeButton: true,
          className: 'full',
          position: `${window.innerWidth > 991 ? 'top' : 'bottom'} center`,
          duration: 3600000,
          notOverClick: true,
          immediately: true,
        })
      } finally {
        setLoadingSubmit(false)
      }
    }
  }

  if (loading || !user?.id) {
    return (
      <div className={`edit-page box mb-0 loading ${className}`} {...rest}>
        <LoaderComponent size='bigger' />
      </div>
    )
  }

  return <div className={`edit-page box mb-0 ${className}`} {...rest}>
    {user &&
      <div className='grid head'>
        <div className='wrapper-pic' style={{
          backgroundImage: coverImg ? `url(${coverImg})` : `url("https://storage.googleapis.com/wall-images/users/${user?.id}/cover.jpg")`
        }}>
          <HoneycombImageComponent onClick={handleProfileClick} src={profileImg || `https://storage.googleapis.com/wall-images/users/${user?.id}/profile.jpg`} />
          <input
            type="file"
            style={{ display: 'none' }}
            ref={profileImgInputRef}
            onChange={handleProfileFileChange}
          />
          <input
            type="file"
            style={{ display: 'none' }}
            ref={coverImgInputRef}
            onChange={handleCoverFileChange}
          />
          <button onClick={handleCoverClick} className="upload-cover"><i className="fa-solid fa-camera"></i></button>
        </div>
      </div>
    }
    <div className={`edit-profile-form-wrapper mb-0 ${className}`} {...rest}>
      <h4 className='my-5 text-center' style={{ width: '100%' }}>
        {t('title')}
      </h4>
      <form onSubmit={handleSubmit}>
        {
          user?.is_verified ? (<div className='warning-username box background-blue white'>
            <i className="fas fa-info-circle"></i><span><small>{t('usernameDisclaimer')}</small></span>
          </div>) : null
        }
        <Input
          id='edit-username'
          name='edit-username'
          label={t('fields.username')}
          placeholder={t('fields.username') as string}
          onChange={(value) => setEdit({ ...edit, username: value })}
          value={edit.username}
          onTimeout={(bool) => setUsernameTiping(!bool)}
          externalError={usernameInUse ? t('errors.USERNAME_IN_USE') : ''}
          externalIcon={user?.is_verified ? <VerifiedIconComponent /> : ''}
        />
        <Input
          id='edit-name'
          name='edit-name'
          label={t('fields.name')}
          type='text'
          placeholder={t('fields.name') as string}
          onChange={(value) => setEdit({ ...edit, name: value?.trim() })}
          value={edit.name}
        />
        <Input
          id='edit-email'
          name='edit-email'
          label={t('fields.email')}
          type='email'
          placeholder={t('fields.email') as string}
          onChange={(value) => setEdit({ ...edit, email: value })}
          onTimeout={(bool) => setEmailTiping(!bool)}
          externalError={emailInUse ? t('errors.EMAIL_IN_USE') : ''}
          value={edit.email}
        />
        <InputDate
          id='edit-birthday'
          name='edit-birthday'
          label={t('fields.birthday')}
          type='date'
          placeholder={t('fields.birthday') as string}
          onChange={(value) => setEdit({ ...edit, birthday: value })}
          value={edit.birthday}
        />
        <Input
          id='edit-link'
          name='edit-link'
          label={t('fields.link')}
          placeholder={t('fields.link') as string}
          onChange={(value) => setEdit({ ...edit, link: value })}
          type='url'
          value={edit.link}
        />
        <Input
          id='edit-bio'
          name='edit-bio'
          label={t('fields.bio')}
          placeholder={t('fields.bio') as string}
          onChange={(value) => setEdit({ ...edit, bio: value })}
          type='textarea'
          value={edit.bio}
        />
        <Button
          type='submit'
          loading={loadingSubmit}
          size='md'
          id='button-save-profile'
          disabled={!validateAllValidation()}
          width='100%'
        >
          {t('submit')}
        </Button>
      </form>
      <Button
        width='100%'
        className='mt-5'
        disabled={loadingSubmit}
        size='md'
        color='transparent'
        textColor='var(--text_color)'
        id='button-reset-password-modal'
        onClick={() => setResetPasswordModal(true)}
        style={{ maxWidth: '560px' }}

      >
        {t('reset')}
      </Button>
      <ModalComponent header={
        <h6 className='mb-0'>
          {t('reset')}
        </h6>
      } open={resetPasswordModal} onCloseChange={() => setResetPasswordModal(false)}>
        <form className='reset-password-form' onSubmit={handleResetPasswordSubmit}>
          <p>{t('resetDisclaimer')}</p>
          <Button
            type='submit'
            loading={loadingReset}
            size='lg'
            id='button-reset-password-send'
            width='100%'
          >
            {t('reset')}
          </Button>
        </form>
      </ModalComponent>
    </div>
  </div>
}