import HoneycombImageComponent from '@/shared/components/honeycomb-image/honeycomb-image.component'
import InfinityScroll from '@/shared/components/infinity-scroll/infinity-scroll.component'
import LoaderComponent from '@/shared/components/loader/loader.component'
import UserHelper from '@/shared/helpers/user.helper'
import { Invite } from '@/shared/interfaces/invites-list.interface'
import { User } from '@/shared/interfaces/user.interface'
import userObservable from '@/shared/observables/user.observable'
import InvitesService from '@/shared/services/invites.service'
import { HTMLAttributes, PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Subscription } from 'rxjs'
import './used.page.scoped.scss'

interface Props extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {

}

export default ({ className = '', ...rest }: Props) => {
  const [t] = useTranslation('invitesPage')
  const [tGlobal] = useTranslation()
  const onUserChangeSubscription = useRef<Subscription>()
  const [user, setUser] = useState<User | null>()
  const userHelper = useMemo(() => new UserHelper(), [])
  const [loading, setLoading] = useState<boolean>(true)
  const [loadingPage, setLoadingPage] = useState<boolean>(false)
  const [page, setPage] = useState<number>(1)
  const [hasMore, setHasMore] = useState<boolean>(true)
  const invitesService = useMemo(() => new InvitesService(), [])
  const [invitesUsed, setInvitesUsed] = useState<Invite[]>([])
  const PER_PAGE = 15

  const getInvites = useCallback(async () => {
    try {
      const invites = await invitesService.getInvitesByStatus('invited', page, PER_PAGE)
      setInvitesUsed((i) => i.concat(invites?.data))
      if (invites?.data?.length < PER_PAGE) {
        setHasMore(false)
      }
    } catch (error) {
      // TODO: handle error
    } finally {
      setLoadingPage(false)
      setLoading(false)
    }
  }, [invitesService, page])

  useEffect(() => {
    onUserChangeSubscription.current = userObservable.subscribe((u) => {
      return setUser(u)
    })

    return () => {
      onUserChangeSubscription.current?.unsubscribe()
    }
  }, [])

  useEffect(() => {
    if (!user) return
    setLoading(true)
    setInvitesUsed([])
    setPage(1)
  }, [user])

  useEffect(() => {
    setLoadingPage(true)
    getInvites()
  }, [getInvites, page])

  const formatDate = (date: string) => {
    return new Date(date).toLocaleDateString()
  }

  if (loading) {
    return <div className={`used-page my-5 text-center loader-wrapper ${className}`}>
      <LoaderComponent size='big' />
    </div>
  }

  if (!invitesUsed.length && !loading) {
    return <div className={`used-page ${className}`}>
      <h6 className='mt-5 text-center'>
        {t('usedPage.noInvites')}
      </h6>
    </div>
  }

  return <div className={`used-page ${className}`} {...rest}>
    <InfinityScroll loading={loadingPage} onLoadMore={() => setPage((p) => p + 1)} hasMore={hasMore}>
      <ul className='list'>
        {invitesUsed.map((invite, i) =>
          <li key={`${invite.id}-${i}`}>
            <Link className='wrapper-image' to={invite?.invitedUser?.username ? `/profile/${invite?.invitedUser?.username}` : ''}>
              <HoneycombImageComponent
                src={userHelper.getImageUrl(invite?.invitedUser?.username ? invite?.invited : null)}
              />
            </Link>
            <div className='wrapper-text'>
              {invite?.invitedUser?.username ?
              (
                <Link className='username' to={`/profile/${invite?.invitedUser?.username}`}>
                  {invite?.invitedUser?.username}
                </Link>
              ) : <span className='username'>{tGlobal('notUser')}</span>}
              <span className='code'>
                {t('usedPage.invite')}: {invite?.shortcode}
              </span>
              <span className='date'>
                {t('usedPage.date')}: {formatDate(invite?.createdAt)}
              </span>
            </div>
          </li>
        )}
      </ul>
    </InfinityScroll>
  </div>
}