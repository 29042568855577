import Button from '@/shared/components/button/button.component'
import Input from '@/shared/components/input/input.component'
import ToastHelper from '@/shared/helpers/toast.helper'
import UserService from '@/shared/services/user.service'
import { HTMLAttributes, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, Navigate, useNavigate, useSearchParams } from 'react-router-dom'
import './reset-password.page.scoped.scss'

interface Props extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {

}

export default ({ className = '', ...rest }: Props) => {
  const userService = useMemo(() => new UserService(), [])
  const toast = new ToastHelper().toast
  const [t] = useTranslation('resetPasswordPage')
  const [obbCode, setObbCode] = useState<string | undefined>(undefined)
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const [reset, setReset] = useState({
    password: '',
    confirmPassword: ''
  })
  const [validation, setValidation] = useState({
    password: false,
    confirmPassword: true
  })

  useEffect(() => {
    setObbCode(getURLParameter('oobCode'))
  }, [])

  useEffect(() => {
    if (reset?.password?.length && reset?.confirmPassword?.length) {
      setValidation((v: any) => {
        return {
          ...v,
          confirmPassword: reset?.password === reset?.confirmPassword
        }
      })
    }
  }, [reset?.confirmPassword, reset?.password])

  function getURLParameter(param: string): string | undefined {
    const urlParams: Record<string, string> = {}
    window.location.search
      .substring(1)
      .split('&')
      .forEach(function (item) {
        const paramKeyValue = item.split('=')
        urlParams[paramKeyValue[0]] = decodeURIComponent(paramKeyValue[1])
      })
    return urlParams[param] || 'FALSE'
  }

  const doResetPassword = useCallback(
    async (e: any) => {
      setLoading(true)
      e.preventDefault()
      const payload = {
        password: reset.password,
        oobCode: obbCode,
      }
      try {
        await userService.resetPassword(payload)
        toast.success(t('resetSuccess'), {
          closeButton: true,
          className: 'full',
          position: `${window.innerWidth > 991 ? 'top' : 'bottom'} center`,
          duration: 3600000,
          notOverClick: true,
          immediately: true,
        })
        navigate('/login')
      } catch (err) {
        toast.error(t('errors.PASSWORD_RESET_ERROR'), {
          closeButton: true,
          className: 'full',
          position: `${window.innerWidth > 991 ? 'top' : 'bottom'} center`,
          duration: 3600000,
          notOverClick: true,
          immediately: true,
        })
        setLoading(false)
      }
    },
    [reset, navigate, obbCode, t, toast, userService]
  )

  const validateAllValidation = () => {
    const { password, confirmPassword } = validation
    return password && confirmPassword && reset?.confirmPassword?.length
  }

  if (obbCode === 'FALSE') {
    return <Navigate to='/login/forgot-password' />
  }

  return <div className={`reset-password-page box mb-0 ${className}`} {...rest}>
    <h4 className='mb-0 mt-3'>
      {t('title2')}
    </h4>
    <form onSubmit={doResetPassword}>
      <Input
        id='password-reset'
        name='password-reset'
        type='password'
        label={t('fields.password') as string}
        placeholder={t('fields.password') as string}
        onChange={(value) => setReset({ ...reset, password: value })}
        onValidate={(value) => setValidation({ ...validation, password: value })}
        value={reset.password}
      />
      <Input
        id='confirm-password-reset'
        name='confirm-password-reset'
        type='password'
        label={t('fields.confirmPassword') as string}
        placeholder={t('fields.confirmPassword') as string}
        onChange={(value) => setReset({ ...reset, confirmPassword: value })}
        externalError={!validation.confirmPassword ? t('errors.PASSWORD_NOT_MATCH') : ''}
        value={reset.confirmPassword}
      />
      <Button
        type='submit'
        disabled={!validateAllValidation()}
        loading={loading}
        size='lg'
        id='button-login'
        width='100%'
      >
        {t('resetPassword')}
      </Button>
    </form>
    <footer className='mb-3'>
      <p className='forgot-password'>
        <Link to='/login'>{t('login')}</Link>
      </p>
      <p>
        {t('dontHaveAccount')} <Link to={{
          pathname: '/login/register',
          search: searchParams.toString()
        }}>{t('register')}</Link>
      </p>
    </footer>
  </div>
}