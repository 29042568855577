import { UserFollowUser } from '../interfaces/user-follow-user.interface'
import { User } from '../interfaces/user.interface'
import MainService from './main.service'
//TODO: quebrar algums metodos em outros services
export default class UserService extends MainService {
  constructor() {
    super()
  }

  async getUserInfoByAuth(): Promise<any> {
    const headers = await this.getHeaders()
    return await this.http.get(`${this.URL}/user/auth`, { headers }).then((r) => r.data)
  }

  async getUserInfoByUsername(username: string): Promise<any> {
    if (!username) {
      throw new Error('username is required')
    }
    const headers = await this.getHeaders()
    return await this.http.get(`${this.URL}/user/${username}`, { headers }).then((r) => r.data)
  }

  async getUserMetricsByUsername(username: string): Promise<any> {
    if (!username) {
      throw new Error('username is required')
    }
    const headers = await this.getHeaders()
    return await this.http.get(`${this.URL}/user/${username}/metrics`, { headers }).then((r) => r.data)
  }

  async patchUserInfoById(id: number, payload: any) {
    const headers = await this.getHeaders()
    return await this.http.patch(`${this.URL}/user/${id}`, payload, { headers }).then((r) => r.data)
  }

  async followUsersById(userIdToFollow: number): Promise<any> {
    if (!userIdToFollow) {
      throw new Error('userIdToFollow is required')
    }
    const headers = await this.getHeaders()
    return await this.http.get(`${this.URL}/user/follow/${userIdToFollow}`, { headers }).then((r) => r.data)
  }

  async putFollowById(userIdToFollow: number): Promise<any> {
    if (!userIdToFollow) {
      throw new Error('userIdToFollow is required')
    }
    const headers = await this.getHeaders()
    return await this.http.put(`${this.URL}/user/follow/${userIdToFollow}`, {}, { headers }).then((r) => r.data)
  }

  async isFollowingUser(followerId: number): Promise<any> {
    if (!followerId) {
      throw new Error('followerId is required')
    }
    const headers = await this.getHeaders()
    return await this.http.get(`${this.URL}/user/follow/${followerId}`, { headers }).then((r) => r.data)
  }

  async getUserFollowByUsernameAuth(username: string, type: 'following' | 'followers' = 'following', page = 1, perPage = 30): Promise<UserFollowUser[]> {
    if (!username) {
      throw new Error('username is required')
    }
    const headers = await this.getHeaders()
    return await this.http.get(`${this.URL}/user/${username}/${type}/auth?page=${page}&perPage=${perPage}`, { headers }).then((r) => r.data)
  }

  async getUserNftList(): Promise<any> {
    const headers = await this.getHeaders()
    return await this.http.get(`${this.URL}/user/nft/list`, { headers }).then((r) => r.data)
  }

  async getUserSearch(search: string, page = 1, perPage = 30): Promise<User[]> {
    search = search.trim()
    if (!search) {
      // generate random search with one letter
      search = String.fromCharCode(Math.floor(Math.random() * 26) + 97)
    }
    const headers = await this.getHeaders()
    return await this.http.get(`${this.URL}/user/search/${search}?page=${page}&perPage=${perPage}`, { headers }).then((r) => r.data)
  }

  async getUserAuthSearch(search: string, page = 1, perPage = 30, onlyNotFollowing = false): Promise<User[]> {
    search = search.trim()
    if (!search) {
      // generate random search with one letter
      search = String.fromCharCode(Math.floor(Math.random() * 26) + 97)
      onlyNotFollowing = true
    }
    const headers = await this.getHeaders()
    return await this.http.get(`${this.URL}/user/search/auth/${search}?page=${page}&perPage=${perPage}&onlyNotFollowing=${onlyNotFollowing}`, { headers }).then((r) => r.data)
  }

  async getUploadPhotoUrlSigned(type: string) {
    const headers = await this.getHeaders()
    return await this.http.get(`${this.URL}/user/photo?type=${type}`, { headers }).then((r) => r.data)
  }

  async getNftListByUsername(username: string, page = 1, perPage = 30): Promise<any> {
    if (!username) {
      throw new Error('username is required')
    }
    const headers = await this.getHeaders()
    return await this.http.get(`${this.URL}/user/nft/list/${username}?perPage=${perPage ? perPage : 40}&page=${page ? page : 1}`, { headers }).then((r) => r.data)
  }

  async getHiddenNftListByUsername(page = 1, perPage = 30): Promise<any> {
    const headers = await this.getHeaders()
    return await this.http.get(`${this.URL}/user/nft/list/hidden?perPage=${perPage ? perPage : 40}&page=${page ? page : 1}`, { headers }).then((r) => r.data)
  }

  async resetPassword(payload: any): Promise<any> {
    if (!payload) {
      throw new Error('reset payload is required!')
    }
    const headers = await this.getHeaders()
    return await this.http.post(`${this.URL}/auth/change-password`, { password: payload.password, oobCode: payload.oobCode }, { headers }).then((r) => r.data)
  }

  async countField(fieldName: string, value: string) {
    if (!fieldName) {
      throw new Error('fieldName is required!')
    }
    if (!value) {
      throw new Error('value is required!')
    }
    const headers = await this.getHeaders()
    return await this.http.get(`${this.URL}/users/count/${fieldName}/${value}`, { headers }).then((r) => r.data).catch((e) => {
      throw e
    })
  }

  async getSavedNftByAuth(page = 1, perPage = 30): Promise<any> {
    const headers = await this.getHeaders()
    return await this.http.get(`${this.URL}/user/saves?page=${page}&perPage=${perPage}`, { headers }).then((r) => r.data).catch((e) => {
      throw e
    })
  }

  async deleteUser(): Promise<any> {
    const headers = await this.getHeaders()
    return await this.http.delete(`${this.URL}/user`, { headers }).then((r) => r.data).catch((e) => {
      throw e
    })
  }

  async getUserVerification(username: string): Promise<any> {
    if (!username) {
      throw new Error('username is required')
    }
    return await this.http.get(`${this.URL}/user/verified/${username}`,).then((r) => r.data).catch((e) => {
      throw e
    })
  }

  async toggleBlockUser(userId: number) {
    if (!userId) {
      throw new Error('userId is required')
    }
    const headers = await this.getHeaders()
    return await this.http.put(`${this.URL}/user/blocks/${userId}`, {}, { headers }).then((r) => r.data).catch((e) => {
      throw e
    })
  }

  async isBlockUser(userId: number) {
    if (!userId) {
      throw new Error('userId is required')
    }
    const headers = await this.getHeaders()
    return await this.http.get(`${this.URL}/user/blocks/${userId}`, { headers }).then((r) => r.data).catch((e) => {
      throw e
    })
  }
}