import InputComponent from '@/shared/components/input/input.component'
import Tabs from '@/shared/components/tabs/tabs.component'
import { HTMLAttributes, PropsWithChildren, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import NftSearchPage from './pages/nft-search/nft-search.page'
import UsersSearchPage from './pages/users-search/users-search'
import './search.page.scoped.scss'
import './search.page.scss'

interface Props extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {

}

export default ({ className = '', ...rest }: Props) => {
  const [t] = useTranslation('searchPage')
  const [tGlobal] = useTranslation()
  const [textSearch, setTextSearch] = useState<string>('')
  const [childrenTextSearch, setChildrenTextSearch] = useState<string>('')
  const [onTimeout, setOnTimeout] = useState<boolean>(false)
  const [tabActive , setTabActive] = useState<string>('nft')
  const navigate = useNavigate()
  const location = useLocation()
  const menu = [
    {
      name: 'nft',
      title: t('menu.nft'),
      onClick: () => setTabActive('nft'),
      icon: 'fa-grid-2'

    },
    {
      name: 'users',	
      title: t('menu.user'),
      onClick: () => setTabActive('users'),
      icon: 'fa-user'
    }
  ]

  const onClickNav = (name: string) => {
    setTabActive(name)
    navigate(`/search/${name}`)
  }

  const OnRedirect = () => {
    setTabActive('nft')
    return <Navigate to={{ pathname: '/search/nft' }} />
  }


  useEffect(() => {
    if (onTimeout) {
      setChildrenTextSearch(textSearch)
    }
  }, [onTimeout, textSearch])

  const defaultPage = useCallback(() => {
    const finalPath = location?.pathname?.split('/')?.pop()
    switch (finalPath) {
      case 'users':
        return 1
      case 'nft':
        return 0
      default:
        return 0
    }
  }, [location])

  return <div className={`search-page box ${className}`} {...rest}>
    <InputComponent 
      id='search-input'
      name='search-input'	
      placeholder={tGlobal('search') as string}
      value={textSearch}
      onTimeout={(e) => setOnTimeout(e)}
      onChange={(e) => setTextSearch(e)}
      errorSpan={false}
      externalIcon={<i className="fa-solid fa-magnifying-glass"></i>}
    />
    <Tabs className='nav' tabsCenter defaultActiveIndex={defaultPage()} lineStyle noContent>
      {
        menu.map((item, index) => (
          <Tabs.Tab
            onClick={(e) => onClickNav(item.name)}
            key={index}
            title={(
              <div className='tab-item'>
                <i className={`fa-regular ${item?.icon}`} />
                <span>
                  {t(item?.title)}
                </span>
              </div>
            )}
          />
        ))
      }
    </Tabs>
    <Routes>
      <Route index element={<OnRedirect />} />
      <Route path='users' element={<UsersSearchPage searchText={childrenTextSearch} />} />
      <Route path='nft' element={<NftSearchPage searchText={childrenTextSearch} />} />
      <Route path="*" element={<Navigate to={{ pathname: '/error/not-found' }} />} />
    </Routes>
  </div>
}