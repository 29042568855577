import LoaderComponent from '@/shared/components/loader/loader.component'
import { Nft } from '@/shared/interfaces/nft.interface'
import { User } from '@/shared/interfaces/user.interface'
import UserService from '@/shared/services/user.service'
import { HTMLAttributes, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import FeedListProfileComponent from '../../components/feed-list-profile/feed-list-profile.component'
import './saves-nft.page.scoped.scss'

interface Props extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {
  user: User | null
  loggedUser: User | null
}

export default ({ user, loggedUser,className = '', ...rest }: Props) => {
  const [t] = useTranslation('userProfilePage')
  const userService = useMemo(() => new UserService(), [])
  const [hasMore, setHasMore] = useState(true)
  const [list, setList] = useState<Nft[]>([])
  const [loading, setLoading] = useState(true)
  const [loadingScroll, setLoadingScroll] = useState(false)
  const [requestInProcess, setRequestInProcess] = useState(false)
  const [page, setPage] = useState(1)

  const loadFeed = useCallback(async () => {
    try {
      setLoading(true)
      setRequestInProcess(true)
      const list = await userService.getSavedNftByAuth()
      setList(list || [])
    } catch (error) {
      // TODO: handle error
    } finally {
      setLoading(false)
      setRequestInProcess(false)
    }
  }, [userService])

  useEffect(() => {
    if (!user && !requestInProcess) {
      return
    }
    loadFeed()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const onScroll = useCallback(async () => {
    if (!loadingScroll && hasMore) {
      try {
        setLoadingScroll(true)
        const r = await userService.getSavedNftByAuth(page + 1)
        setList((old) => old.concat(r || []))
        setPage(page + 1)
        setHasMore(r?.length > 0)
      } catch (error) {
      } finally {
        setLoadingScroll(false)
      }
    }
  }, [loadingScroll, hasMore, userService, page])

  if (loading) {
    return <div className={`my-3 loading ${className}`} {...rest}>
      <LoaderComponent size='big' />
    </div>
  }

  return <FeedListProfileComponent
    onScroll={onScroll}
    list={list}
    hasMore={hasMore}
    loading={loading}
    loadingScroll={loadingScroll}
  />
}