export default class NftHelper {
  parseNFTUrl(url: string) {
    if (url?.startsWith('ipfs://')) {
      return `https://ipfs.io/ipfs/${url.replace('ipfs://', '')}`
    }
    return url
  }

  async getContentTypeFromURL(url: string) {
    try {
      const response = await fetch(url, {
        method: 'HEAD'
      })
      return response?.headers?.get('content-type')
    } catch (error) {
      // TODO: handle error
      return null
    }
  }
}