import { Notification } from '@interfaces/notification.interface'
import MainService from './main.service'

export default class NotificationService extends MainService {
  constructor() {
    super()
  }
  async getNotifications(page = 1, perPage = 30): Promise<Notification[]> {
    const headers = await this.getHeaders()
    return await this.http.get<Notification[]>(`${this.URL}/notifications?page=${page}&perPage=${perPage}`, { headers }).then((r) => r.data)
  }

  async patchNotification(notificationId: any): Promise<any> {
    if (!notificationId) {
      throw new Error('notificationId is required!')
    }
    const headers = await this.getHeaders()
    return await this.http.patch(`${this.URL}/notifications/read/${notificationId}`, {}, { headers }).then((r) => r.data)
  }

  async getCount(): Promise<any> {
    const headers = await this.getHeaders()
    return await this.http.get(`${this.URL}/notifications/count`, { headers }).then((r) => r.data)
  }
}
