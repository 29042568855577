import InfinityScrollComponent from '@/shared/components/infinity-scroll/infinity-scroll.component'
import LoaderComponent from '@/shared/components/loader/loader.component'
import UserListedComponent from '@/shared/components/user-listed/user-listed.component'
import { User } from '@/shared/interfaces/user.interface'
import UserService from '@/shared/services/user.service'
import { HTMLAttributes, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import './users-search.scoped.scss'


interface Props extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {
  searchText?: string
}

export default ({ searchText = '', className = '', ...rest }: Props) => {
  const [tGlobal] = useTranslation()
  const userService = useMemo(() => new UserService(), [])
  const [users, setUsers] = useState<User[]>([])
  const [usersPage, setUsersPage] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingPage, setLoadingPage] = useState<boolean>(false)
  const [hasMore, setHasMore] = useState<boolean>(true)
  const [tryAutoSearch, setTryAutoSearch] = useState<number>(0)
  const PER_PAGE = 30

  const getUsers = useCallback(async () => {
    if (usersPage < 1) {
      return
    }
    try {
      const users = await userService.getUserAuthSearch(searchText, usersPage, PER_PAGE)
      setUsers((old: any) => {
        if (usersPage === 1) {
          return users
        }
        return [...old, ...users]
      })
      setHasMore(users?.length >= PER_PAGE)
      if (!users?.length && !searchText && usersPage === 1) {
        setTryAutoSearch((old) => {
          if (old < 3) {
            getUsers()
          }
          return old + 1
        })
      }
    } catch (error) {
      // TODO: handle error
      setHasMore(false)
    } finally {
      setLoading(false)
      setLoadingPage(false)
    }
  }, [usersPage, userService, searchText])

  useEffect(() => {
    setLoading(true)
    setUsersPage(0)
    setHasMore(true)
    setTimeout(() => {
      setUsersPage(1)
    }, 500)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText])

  useEffect(() => {
    getUsers()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersPage])

  const onScroll = useCallback(() => {
    if (loadingPage || !hasMore || loading) {
      return
    }
    setLoadingPage(true)
    setUsersPage((p) => {
      const newP = p + 1
      setLoadingPage(newP !== 1)
      return newP
    })
  }, [loadingPage, hasMore, loading])

  if (loading) {
    return <div className={`users-search my-5 text-center loader-wrapper ${className}`}>
    <LoaderComponent size='big' />
  </div>
  }

  if (users?.length === 0) {
    return <div className={`users-search ${className}`}>
      <p className='empty'>
        {tGlobal('empty')}
      </p>
    </div>
  }

  return <div className={`users-search ${className}`} {...rest}>
    <InfinityScrollComponent
      loading={loadingPage}
      onLoadMore={onScroll}
      endMessage={' '}
      hasMore={hasMore}
    >
      <div className='wrapper'>
        {
          users?.map((user, index) => {
            return <UserListedComponent key={index} user={user} />
          })
        }
      </div>
    </InfinityScrollComponent>
  </div>
}