import './modal-confirm.component.scoped.scss'
import Modal from '@/shared/components/modal/modal.component'
import { HTMLAttributes, PropsWithChildren, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import ButtonComponent from '@/shared/components/button/button.component'

interface Props extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {
  open?: boolean
  onCloseChange?: () => any
  onConfirmChange?: () => any
  modalSize?: 'sm' | 'md' | 'lg' | 'xl'
}

export default ({ open = false, onCloseChange, onConfirmChange, modalSize = 'sm', className = '', children, ...rest }: Props) => {
  const [t] = useTranslation('modalConfirm')

  const handleConfirm = useCallback(() => {
    if (onConfirmChange && typeof onConfirmChange === 'function') {
      onConfirmChange()
    }
  }, [onConfirmChange])

  const handleClose = useCallback(() => {
    if (onCloseChange && typeof onCloseChange === 'function') {
      onCloseChange()
    }
  }, [onCloseChange])

  return (<Modal
    open={open}
    onCloseChange={onCloseChange}
    modalSize={modalSize}
    header={<h6 className='m-0'>{t('title')}</h6>}
    footer={<div className='footer'>
      <ButtonComponent size='sm' onClick={() => handleClose()}>{t('cancel')}</ButtonComponent>
      <ButtonComponent size='sm' onClick={() => handleConfirm()}>{t('confirm')}</ButtonComponent>
    </div>}
    className={`modal-confirm ${className}`}
    {...rest}
  >
    {children ? children : <p className='my-3'>{t('default')}</p>}
  </Modal>)
}