import Button from '@/shared/components/button/button.component'
import LanguageSelector from '@/shared/components/language-selector/language-selector'
import Logo from '@/shared/components/logo/logo.component'
import NotFoundPage from '@modules/error/pages/not-found/not-found.page'
import { HTMLAttributes, PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import './error.layout.scoped.scss'

interface Props extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {

}

export default ({ className = '', ...rest }: Props) => {
  const [t] = useTranslation('errorLayout')
  const navigate = useNavigate()

  return <div className={`error-layout ${className}`} {...rest}>
    <header>
      <Logo className='main-logo' />
    </header>
    <main>
      <Routes>
        <Route index element={<Navigate to={{ pathname: '/error/not-found' }} />} />
        <Route path="/not-found" element={<NotFoundPage />} />
        <Route path="*" element={<Navigate to={{ pathname: '/error/not-found' }} />} />
      </Routes>
    </main>
    <footer>
      <Button
        onClick={() => navigate('/')}
      >
        {t('homeButton')}
      </Button>
      <div className='actions'>
        <LanguageSelector />
      </div>
    </footer>
  </div>
}