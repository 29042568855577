import uploadImage from '@/assets/images/png/upload_icon.png'
import { File, NFTStorage } from 'nft.storage'
import React from 'react'
import './drag-drop-nft.component.scoped.scss'

interface Props {
  onImageDone: (cid: string) => void
}

// added
const DragDropNft = React.forwardRef<{ handleFiles: (files: FileList) => Promise<void | string> }, Props>(({ onImageDone }, ref) => {
  // export default ({ onImageDone }: Props) => {
  const [dragActive, setDragActive] = React.useState(false)
  const [imageUrl, setImageUrl] = React.useState('')
  // added
  const inputRef = React.useRef<HTMLInputElement>(null)
  const storage = new NFTStorage({ token: process.env.REACT_APP_ENV_NFT_STORAGE_KEY as string })
  // handle drag events
  const handleDrag = function (e: { preventDefault: () => void; stopPropagation: () => void; type: string; }) {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true)
    } else if (e.type === 'dragleave') {
      setDragActive(false)
    }
  }

  const handleFiles = async function (files: FileList, isJsonUpload = false): Promise<string | void> {
    const resp = await storage.storeBlob(new File([files[0]], files[0].name, { type: files[0].type }))
    if (!resp) throw new Error('Failed to upload file')
    const fileLink = `https://cloudflare-ipfs.com/ipfs/${resp}`

    if (!isJsonUpload) {
      onImageDone(fileLink)
      setImageUrl(fileLink)
    } else {
      return fileLink
    }
  }

  // added
  React.useImperativeHandle(ref, () => ({
    handleFiles,
  }))

  const onButtonClick = () => {
    if (inputRef && inputRef.current)
      inputRef.current.click()
  }
  // triggers when file is dropped
  const handleDrop = function (e: { preventDefault: () => void; stopPropagation: () => void; dataTransfer: { files: FileList; }; }) {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
    if (e.dataTransfer.files && e.dataTransfer.files.length) {
      handleFiles(e.dataTransfer.files)
    }
  }
  // triggers when file is selected with click
  const handleChange = function (e: { preventDefault: () => void; target: { files: FileList | null; }; }) {
    e.preventDefault()
    if (e.target.files && e.target.files.length) {
      handleFiles(e.target.files)
    }
  }
  return (
    <div>
      <form id="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()} className={imageUrl ? 'uploaded' : ''}>
        {
          imageUrl ? <img id="img-uploaded" src={imageUrl} width={200} /> : (
            <>
              <input ref={inputRef} type="file" id="input-file-upload" multiple={false} onChange={handleChange} />
              <label id="label-file-upload" htmlFor="input-file-upload">
                <div className="upload-container" onClick={onButtonClick}>
                  <img src={uploadImage} width={20} />
                  <p>Carregar arquivo de mídia</p>
                  <p>Arraste e solte, ou clique para carregar um JPG, PNG, GIF, MOV MP4 FILE</p>
                </div>
              </label>
              {dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div>}
            </>
          )
        }
      </form>
    </div>
  )
})

export default React.memo(DragDropNft)