import enModalFollowersList from '@/modules/user/components/modal-followers-list/locales/en.json'
import esModalFollowersList from '@/modules/user/components/modal-followers-list/locales/es.json'
import ptModalFollowersList from '@/modules/user/components/modal-followers-list/locales/pt.json'
import enButtonCopyText from '@/shared/components/button-copy-text/locales/en.json'
import esButtonCopyText from '@/shared/components/button-copy-text/locales/es.json'
import ptButtonCopyText from '@/shared/components/button-copy-text/locales/pt.json'
import enModalConfirm from '@/shared/components/modal-confirm/locales/en.json'
import esModalConfirm from '@/shared/components/modal-confirm/locales/es.json'
import ptModalConfirm from '@/shared/components/modal-confirm/locales/pt.json'
import enNftFeedComponent from '@/shared/components/nft-feed/locales/en.json'
import esNftFeedComponent from '@/shared/components/nft-feed/locales/es.json'
import ptNftFeedComponent from '@/shared/components/nft-feed/locales/pt.json'
import enNotifications from '@/shared/components/notifications/locales/en.json'
import esNotifications from '@/shared/components/notifications/locales/es.json'
import ptNotifications from '@/shared/components/notifications/locales/pt.json'
import enToggleThemeComponent from '@/shared/components/toggle-theme/locales/en.json'
import esToggleThemeComponent from '@/shared/components/toggle-theme/locales/es.json'
import ptToggleThemeComponent from '@/shared/components/toggle-theme/locales/pt.json'
import enDefaultLayout from '@/shared/layouts/default/locales/en.json'
import esDefaultLayout from '@/shared/layouts/default/locales/es.json'
import ptDefaultLayout from '@/shared/layouts/default/locales/pt.json'
import enGlobal from '@assets/locales/en.json'
import esGlobal from '@assets/locales/es.json'
import ptGlobal from '@assets/locales/pt.json'
import enOnboarding from '@components/on-boarding/locales/en.json'
import esOnboarding from '@components/on-boarding/locales/es.json'
import ptOnboarding from '@components/on-boarding/locales/pt.json'
import enErrorLayout from '@modules/error/layouts/error/locales/en.json'
import esErrorLayout from '@modules/error/layouts/error/locales/es.json'
import ptErrorLayout from '@modules/error/layouts/error/locales/pt.json'
import enNotFoundPage from '@modules/error/pages/not-found/locales/en.json'
import esNotFoundPage from '@modules/error/pages/not-found/locales/es.json'
import ptNotFoundPage from '@modules/error/pages/not-found/locales/pt.json'
import enResetPage from '@modules/login/pages/forgot-password/locales/en.json'
import esResetPage from '@modules/login/pages/forgot-password/locales/es.json'
import ptResetPage from '@modules/login/pages/forgot-password/locales/pt.json'
import enLoginPage from '@modules/login/pages/login/locales/en.json'
import esLoginPage from '@modules/login/pages/login/locales/es.json'
import ptLoginPage from '@modules/login/pages/login/locales/pt.json'
import enRegisterPage from '@modules/login/pages/register/locales/en.json'
import esRegisterPage from '@modules/login/pages/register/locales/es.json'
import ptRegisterPage from '@modules/login/pages/register/locales/pt.json'
import enSearchPage from '@modules/nft/pages/search/locales/en.json'
import esSearchPage from '@modules/nft/pages/search/locales/es.json'
import ptSearchPage from '@modules/nft/pages/search/locales/pt.json'
import enDeleteAccountPage from '@modules/settings/pages/delete-account/locales/en.json'
import esDeleteAccountPage from '@modules/settings/pages/delete-account/locales/es.json'
import ptDeleteAccountPage from '@modules/settings/pages/delete-account/locales/pt.json'
import enGeneralConfigurationPage from '@modules/settings/pages/general-settings/locales/en.json'
import esGeneralConfigurationPage from '@modules/settings/pages/general-settings/locales/es.json'
import ptGeneralConfigurationPage from '@modules/settings/pages/general-settings/locales/pt.json'
import enInvitesPage from '@modules/user/pages/invites/locales/en.json'
import esInvitesPage from '@modules/user/pages/invites/locales/es.json'
import ptInvitesPage from '@modules/user/pages/invites/locales/pt.json'
import enUserProfilePage from '@modules/user/pages/user-profile/locales/en.json'
import esUserProfilePage from '@modules/user/pages/user-profile/locales/es.json'
import ptUserProfilePage from '@modules/user/pages/user-profile/locales/pt.json'
import enWalletsPage from '@modules/user/pages/wallets/locales/en.json'
import esWalletsPage from '@modules/user/pages/wallets/locales/es.json'
import ptWalletsPage from '@modules/user/pages/wallets/locales/pt.json'
import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

export const defaultNS = 'default'
export const resources = {
  en: {
    default: enGlobal,
    loginPage: enLoginPage,
    notFoundPage: enNotFoundPage,
    errorLayout: enErrorLayout,
    toggleThemeComponent: enToggleThemeComponent,
    registerPage: enRegisterPage,
    resetPasswordPage: enResetPage,
    defaultLayout: enDefaultLayout,
    userProfilePage: enUserProfilePage,
    generalConfigurationPage: enGeneralConfigurationPage,
    onboarding: enOnboarding,
    nftFeedComponent: enNftFeedComponent,
    invitesPage: enInvitesPage,
    buttonCopyText: enButtonCopyText,
    notifications: enNotifications,
    searchPage: enSearchPage,
    walletsPage: enWalletsPage,
    modalConfirm: enModalConfirm,
    deleteAccountPage: enDeleteAccountPage,
    modalFollowersList: enModalFollowersList
  },
  pt: {
    default: ptGlobal,
    loginPage: ptLoginPage,
    notFoundPage: ptNotFoundPage,
    errorLayout: ptErrorLayout,
    toggleThemeComponent: ptToggleThemeComponent,
    registerPage: ptRegisterPage,
    resetPasswordPage: ptResetPage,
    defaultLayout: ptDefaultLayout,
    userProfilePage: ptUserProfilePage,
    generalConfigurationPage: ptGeneralConfigurationPage,
    onboarding: ptOnboarding,
    nftFeedComponent: ptNftFeedComponent,
    invitesPage: ptInvitesPage,
    buttonCopyText: ptButtonCopyText,
    notifications: ptNotifications,
    searchPage: ptSearchPage,
    walletsPage: ptWalletsPage,
    modalConfirm: ptModalConfirm,
    deleteAccountPage: ptDeleteAccountPage,
    modalFollowersList: ptModalFollowersList
  },
  es: {
    default: esGlobal,
    loginPage: esLoginPage,
    notFoundPage: esNotFoundPage,
    errorLayout: esErrorLayout,
    toggleThemeComponent: esToggleThemeComponent,
    registerPage: esRegisterPage,
    resetPasswordPage: esResetPage,
    defaultLayout: esDefaultLayout,
    userProfilePage: esUserProfilePage,
    generalConfigurationPage: esGeneralConfigurationPage,
    onboarding: esOnboarding,
    nftFeedComponent: esNftFeedComponent,
    invitesPage: esInvitesPage,
    buttonCopyText: esButtonCopyText,
    notifications: esNotifications,
    searchPage: esSearchPage,
    walletsPage: esWalletsPage,
    modalConfirm: esModalConfirm,
    deleteAccountPage: esDeleteAccountPage,
    modalFollowersList: esModalFollowersList
  },
} as const

i18n
  .use(initReactI18next)
  .use(Backend)
  .init({
    defaultNS,
    ns: [
      'default',
      'loginPage',
      'notFoundPage',
      'errorLayout',
      'toggleThemeComponent',
      'registerPage',
      'defaultLayout',
      'userProfilePage',
      'generalConfigurationPage',
      'nftFeedComponent',
      'invitesPage',
      'buttonCopyText',
      'notifications',
      'searchPage',
      'walletsPage',
      'modalConfirm',
      'deleteAccountPage',
      'modalFollowersList'
    ],
    load: 'all',
    supportedLngs: ['en', 'es', 'pt'],
    lng: navigator.language?.split('-')[0] || 'en',
    fallbackLng: 'en',
    debug: false,
    cache: {
      enabled: false,
    },
    resources,
  })

export default i18n
