import Button from '@/shared/components/button/button.component'
import Input from '@/shared/components/input/input.component'
import { Comment } from '@/shared/interfaces/comment.interface'
import { Nft } from '@/shared/interfaces/nft.interface'
import CommentsService from '@/shared/services/comments.service'
import { HTMLAttributes, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CommentComponent from './comment/comment.component'
import './nft-comments.component.scoped.scss'

interface Props extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {
  nft: Nft
  commentsPerPage?: number
}

export default ({ nft, commentsPerPage = 3, className = '', ...rest }: Props) => {
  const [tGlobal] = useTranslation()
  const [loadingPageComments, setLoadingPageComments] = useState(false)
  const [loadingSendComments, setLoadingSendComments] = useState(false)
  const [commentsHasMore, setCommentsHasMore] = useState<boolean>(true)
  const [comments, setComments] = useState<Comment[]>([])
  const [pageComments, setPageComments] = useState<number>(1)
  const commentsService = useMemo(() => new CommentsService(), [])
  const [newCommentText, setNewCommentText] = useState<string>('')

  const loadPageComments = useCallback(async () => {
    if (!commentsHasMore) {
      return
    }
    try {
      setLoadingPageComments(true)
      const commentsResponse = await commentsService.getCommentsByNftId(nft.id, pageComments, commentsPerPage)
      if (commentsResponse?.data?.data?.length < commentsPerPage) {
        setCommentsHasMore(false)
      }
      setComments((c) => [...c, ...commentsResponse?.data?.data])
    } catch (err) {
      // silence is golden
    } finally {
      setLoadingPageComments(false)
    }
  }, [commentsHasMore, commentsService, nft.id, pageComments, commentsPerPage])

  useEffect(() => {
    loadPageComments()
  }, [loadPageComments, pageComments])

  const sendComment = useCallback(async (event: any) => {
    event.preventDefault()
    if (!newCommentText || loadingSendComments) {
      return
    }
    if (!loadingSendComments) {
      setLoadingSendComments(true)
      try {
        const response = await commentsService.postCommentsByNftId(nft.id, newCommentText)
        setComments((c) => [...c, response?.comment])
        setNewCommentText('')
      } catch (err) {
        // silence is golden
      } finally {
        setLoadingSendComments(false)
      }
    }
  }, [loadingSendComments, newCommentText, commentsService, nft.id])

  const onDeleteComment = useCallback((commentId: string | number) => {
    setComments((c) => c.filter((comment) => comment.id !== commentId))
  }, [])

  if (!nft) {
    return null
  }

  return <div className={`nft-comments ${className}`} {...rest}>
    {comments?.length ? 
      <ul className='comments'>
        {comments.map((comment, index) =>
          <li key={`${comment.id}-${index}`}>
           <CommentComponent nft={nft} comment={comment} onDeleteComment={(commentId) => onDeleteComment(commentId)} />
          </li>
        )}
      </ul>
    : null}
    {commentsHasMore && !loadingPageComments &&
      <button className='see-more-button' onClick={() => setPageComments(pageComments + 1)}>
        {tGlobal('more')} {tGlobal('comments')}
      </button>
    }
    <form className='add-comment' onSubmit={(e) => sendComment(e)}>
      <Input
        id={`comment-insert-${nft.id}`}
        name={`comment-insert-${nft.id}`}
        placeholder={tGlobal('addComment') as string}
        errorSpan={false}
        value={newCommentText}
        readOnly={loadingSendComments}
        onChange={(val) => setNewCommentText(val)}
        style={{
          minHeight: 'initial',
          height: '30px',
          padding: '0.5rem',
          border: 'none',
          fontSize: '0.86rem'
        }}
      />
      <Button
        loading={loadingSendComments}
        className='send-comment'
        type='submit'
        color='transparent'
        borderColor='transparent'
        textColor='var(--title_color)'
        size='sm'
        customPadding='0.25rem'
        hideChildrenOnLoading
      >
        <i className="fa-solid fa-paper-plane-top"></i>
      </Button>
    </form>
  </div>
}