import { HTMLAttributes, PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import './verified-icon.component.scoped.scss'

interface Props extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {}

export default ({ className = '', style, ...rest }: Props) => {
  const [t] = useTranslation()
  return (
    <i
      title={t('verified') as string}
      className={`verifiyed fa-solid fa-badge-check ${className}`}
      style={
        {
          ...style
        } as any
      }
      {...rest}
    />
  )
}
