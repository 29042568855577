import NotificationsFeedComponent from '@/shared/components/notifications/components/notifications-feed/notifications-feed.component'
import { HTMLAttributes, PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import './notifications.page.scoped.scss'

interface Props extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {

}

export default ({ className = '', ...rest }: Props) => {
  const [t] = useTranslation('notifications')
  
  return <div id='notifications-page' className='notifications-page scrollbar box'>
    <h1>{t('title')}</h1>
    <NotificationsFeedComponent elementToScroll='notifications-page' />
  </div>
}