import MainService from './main.service'

export default class ActionsService extends MainService {
  constructor() {
    super()
  }

  async nftAction(nftId: string | number, action: 'likes' | 'saves' = 'likes') {
    if (!nftId) {
      throw new Error('id is required!')
    }
    const headers = await this.getHeaders()
    return await this.http.patch(`${this.URL}/user/${action}/${nftId}`, {}, { headers }).then((r) => r.data)
  }
}
