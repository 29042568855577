import { Auth, getAuth, signOut } from '@firebase/auth'
import axios from 'axios'
import JwtHelper from '../helpers//jwt.helper'
import localforage from '../helpers/localforage.helper'
const REACT_APP_ENV_NFT_API = process.env.REACT_APP_ENV_NFT_API


export const USER_KEY = 'user_session'
export const USER_FIREBASE_SESSION = 'user_firebase_session'

export default class MainService {
  jwtHelper: JwtHelper
  localforage: any
  http = axios
  USER_KEY = USER_KEY
  USER_FIREBASE_SESSION = USER_FIREBASE_SESSION
  public URL = REACT_APP_ENV_NFT_API
  auth: Auth

  constructor() {
    this.jwtHelper = new JwtHelper()
    this.localforage = localforage
    this.auth = getAuth()
    this.http.interceptors.response.use(
      (response) => { return response },
      (error) => {
        if (error.response.status > 399) {
          this.logout()
        }
        return Promise.reject(error)
      }
    )
  }

  async logout() {
    await signOut(this.auth).then(async () => {
      await this.localforage.removeItem(this.USER_KEY)
      await localforage.removeItem(this.USER_FIREBASE_SESSION)
      window.location.href = '/login'
    })
  }

  async setDefaultHeaders(additionalHeaders?: any) {
    return {
      headers: {
        ...(await this.getHeaders(additionalHeaders)),
        ...additionalHeaders,
      },
    }
  }

  async getHeaders(authorization = true, token?: string, type: any = false) {
    try {
      const headers: any = {}
      if (authorization) {
        token = token || (await this.getJWT())
        if (token?.indexOf('Bearer') === -1) {
          token = token.replace('Bearer ', '')
        }
        headers.Authorization = `Bearer ${token}`
      }

      if (type) {
        headers['content-type'] = type
      }

      return headers
    } catch (err) {
      if (!window.location.pathname?.includes('/login')) {
        await this.localforage.setItem('follow_link', `${window.location.pathname}${window.location.search}`)
      }
      await this.logout()
    }
  }

  async getJWT() {
    const userInfo = await this.localforage.getItem(this.USER_KEY)
    if (!userInfo?.accessToken) {
      throw new Error('user_token_not_found')
    }

    const JWT = userInfo?.accessToken.replace('Bearer ', '')
    if (this.jwtHelper.verifyExpirationToken(JWT)) {
      try {
        if (userInfo?.refreshToken) {
          const { accessToken } = await this.renewToken(userInfo?.refreshToken)
          this.localforage.setItem(this.USER_KEY, { ...userInfo, accessToken })
          return accessToken
        } else {
          throw new Error('user_token_expired')
        }
      } catch (err) {
        await this.logout()
      }
    }
    return JWT
  }

  async renewToken(refreshToken: string): Promise<{ accessToken: string }> {
    return await this.http.post<{ accessToken: string }>(`${this.URL}/auth/renew`, { refreshToken }).then((r) => r.data)
  }
}
