import UserHelper from '@/shared/helpers/user.helper'
import { User } from '@/shared/interfaces/user.interface'
import { MenuItem } from '@/shared/layouts/default/default.layout'
import { HTMLAttributes, PropsWithChildren, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, useNavigate } from 'react-router-dom'
import './menu-desktop.component.scoped.scss'

interface Props extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {
  menu?: MenuItem[],
  user: User
}

export default ({ className = '', menu = [], user, ...rest }: Props) => {
  const userHelper = useMemo(() => new UserHelper(), [])
  const { t } = useTranslation('defaultLayout')
  const navigate = useNavigate()
  const onClickItem = (onClickFn: any) => {
    if (onClickFn && typeof onClickFn === 'function') {
      onClickFn()
    }
  }

  return <nav className={`menu-desktop ${className}`} {...rest}>
    {
      menu.map((item, key) => {
        const icon = item.icon ? <i className={`menu-icon fa-solid ${item.icon}`} /> : null
          if (item.href) {
            return (
              <span key={key}>
                <a href={item.href} target={item.target} id={item.id}>
                  {icon}
                  <span className="menu-label">{t(`menu.${item.label}`)}</span>
                </a>
              </span>
            )
          }
          if (item.path) {
            return (
              <span key={key}>
                <NavLink className={({ isActive }) => (isActive ? 'active' : '')} to={item.path?.replace(':username', user?.username)} id={item.id}>
                  {icon}
                  <span className="menu-label">{t(`menu.${item.label}`)}</span>
                </NavLink>
              </span>
            )
          }
          if (item.onClick) {
            return (
              <span key={key}>
                <button
                  id={item.id}
                  type="button"
                  onClick={(e) => {
                    e.preventDefault()
                    onClickItem(item.onClick)
                  }}
                >
                  {icon}
                  <span className="menu-label">{t(`menu.${item.label}`)}</span>
                </button>
              </span>
            )
          }
      })
    }
    <span className="logout">
      <button type="button" onClick={() => userHelper.logout(navigate)}>
        <i className="fa-solid fa-sign-out-alt" />
        <span className="menu-label">{t('exit')}</span>
      </button>
    </span>
  </nav>
}