import { Invite, InvitesListInterface } from '@interfaces/invites-list.interface'
import MainService from './main.service'

export default class InvitesService extends MainService {
  constructor() {
    super()
  }

  async getAllInvites(page = 1, perPage = 15): Promise<InvitesListInterface> {
    const headers = await this.getHeaders()
    return await this.http.get(`${this.URL}/invites?page=${page}&perPage=${perPage}`, { headers }).then((r) => r.data)
  }

  async getInvitesByStatus(status: 'available' | 'invited', page = 1, perPage = 15): Promise<{
    data: Invite[]
    total: number
  }> {
    const headers = await this.getHeaders()
    return await this.http.get(`${this.URL}/invites/${status}?page=${page}&perPage=${perPage}`, { headers }).then((r) => r.data)
  }

  async postInviteByAuty(): Promise<Invite[]> {
    const headers = await this.getHeaders()
    return await this.http.post(`${this.URL}/invites`, {}, { headers }).then((r) => r.data)
  }

  async postInvitePublic(): Promise<Invite> {
    return await this.http.post(`${this.URL}/invites/public`).then((r) => r.data)
  }
}