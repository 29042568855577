
import { User } from '@/shared/interfaces/user.interface'
import { MenuItem } from '@/shared/layouts/default/default.layout'
import { HTMLAttributes, PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import './footer-mobile.component.scoped.scss'

interface Props extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {
  user: User
}

export default ({ className = '', user, ...rest }: Props) => {
  const { t } = useTranslation('defaultLayout')

  const menu: MenuItem[] = [
    {
      label: 'home',
      path: '/feed',
      icon: 'fa-home',
      id: 'home-mobile-menu'
    },
    {
      label: 'search',
      path: '/search',
      icon: 'fa-search',
      id: 'search-mobile-menu'
    },
    {
      label: 'profile',
      path: '/profile/:username',
      icon: 'fa-user',
      id: 'profile-mobile-menu'
    },
    {
      label: 'wallets',
      path: '/wallets',
      icon: 'fa-wallet',
      id: 'main-menu-wallets'
    },
    {
      label: 'settings',
      path: '/settings',
      icon: 'fa-cog',
      id: 'main-menu-settings'
    }
  ]

  const onClickItem = (onClickFn: any) => {
    if (onClickFn && typeof onClickFn === 'function') {
      onClickFn()
    }
  }

  return (
    <footer className={`footer-mobile ${className}`} {...rest}>
      <nav className="menu">
        {menu.map((item, key) => {
          const icon = item.icon ? <i className={`menu-icon fa-solid ${item.icon}`} /> : null
          if (item.href) {
            return (
              <span key={key}>
                <a href={item.href} target={item.target} id={item.id} title={t(`menu.${item.label}`) as string}>
                  {icon}
                </a>
              </span>
            )
          }
          if (item.path) {
            return (
              <span key={key}>
                <NavLink title={t(`menu.${item.label}`) as string} className={({ isActive }) => (isActive ? 'active' : '')} to={item.path?.replace(':username', user?.username)} id={item.id}>
                  {icon}
                </NavLink>
              </span>
            )
          }
          if (item.onClick) {
            return (
              <span key={key}>
                <button
                  id={item.id}
                  type="button"
                  onClick={(e) => {
                    e.preventDefault()
                    onClickItem(item.onClick)
                  }}
                  title={t(`menu.${item.label}`) as string}
                >
                  {icon}
                </button>
              </span>
            )
          }
        })}
      </nav>    
    </footer>
  )
}