import NftHelper from '@/shared/helpers/nft.helper'
import { Nft } from '@/shared/interfaces/nft.interface'
import defaultImage from '@assets/images/jpg/image.jpg'
import { HTMLAttributes, PropsWithChildren, useEffect, useMemo, useState } from 'react'
import './img-nft.component.scoped.scss'

interface Props extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {
  nft: Nft
}

export default ({ nft, className = '', ...rest }: Props) => {
  const nftHelper = useMemo(() => new NftHelper(), [])
  const [url, setUrl] = useState(nftHelper.parseNFTUrl(nft?.metadata?.image || nft?.metadata?.image_url) || defaultImage)
  const [isVideo, setIsVideo] = useState(false)

  const onImageError = (event: any) => {
    event.target.onerror = null
    event.target.src = defaultImage
  }

  const onVideoError = (event: any) => {
    setIsVideo(false)
  }

  useEffect(() => {
    setUrl(nftHelper.parseNFTUrl(nft?.metadata?.image || nft?.metadata?.image_url) || defaultImage)
  }, [nft, nftHelper])

  useEffect(() => {
    nftHelper.getContentTypeFromURL(url).then((type) => {
      setIsVideo(!!type?.includes('video'))
    })
  }, [nftHelper, url])


  if (isVideo) {
    return <video
      className={`img-fluid ${className}`}
      src={url}
      alt={nft?.name as string}
      title={nft?.name as string}
      onError={onVideoError}
      autoPlay
      loop
      muted
      {...rest as any}
    ><track kind="captions" /></video>
  }

  return <img
    className={`img-fluid ${className}`}
    src={url}
    onError={onImageError}
    alt={nft?.name as string}
    title={nft?.name as string}
    {...rest}
  />
}