import CoinBaseLogo from '@/assets/images/png/coinbase-logo.png'
import EthLogo from '@/assets/images/png/eth-logo.png'
import MetaMaskLogo from '@/assets/images/png/metamask-logo.png'
import TrustLogo from '@/assets/images/png/trust-logo.webp'
import ButtonComponent from '@/shared/components/button/button.component'
import LoaderComponent from '@/shared/components/loader/loader.component'
import ModalConfirm from '@/shared/components/modal-confirm/modal-confirm.component'
import { UserWallet } from '@/shared/interfaces/wallets.interface'
import WalletService from '@/shared/services/wallet.service'
import { HTMLAttributes, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ModalVinculateWallet from './components/modal-vinculate-wallet/modal-vinculate-wallet.component'
import './wallets.page.scoped.scss'

interface Props extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {

}

export default ({ className = '', ...rest }: Props) => {
  const [t] = useTranslation('walletsPage')
  const [tGlobal] = useTranslation()
  const [loading, setLoading] = useState(true)
  const walletsService = useMemo(() => new WalletService(), [])
  const [wallets, setWallets] = useState<UserWallet[]>([])
  const [openModal, setOpenModal] = useState(false)
  const [walletToDelete, setWalletToDelete] = useState<string | null>(null)
  const [openModalVinculate, setOpenModalVinculate] = useState(false)

  const getUserWallets = useCallback(async () => {
    try {
      const wallets = await walletsService.getUserWallets()
      setWallets(wallets)
    } catch (error) {
      // TODO: handle error
    } finally {
      setLoading(false)
    }
  }, [walletsService])

  const onSuccess = useCallback(() => {
    setOpenModalVinculate(false)
    setLoading(true)
    getUserWallets()
  }, [getUserWallets])

  useEffect(() => {
    getUserWallets()
  }, [getUserWallets])

  const parseWalletLogo = useCallback((walletName: string) => {
    switch (walletName) {
      case 'Coinbase Wallet':
        return CoinBaseLogo
      case 'MetaMask':
        return MetaMaskLogo
      case 'Trust Wallet':
        return TrustLogo
      default:
        return EthLogo
    }
  }, [])

  const formatDate = (date: any) => {
    const dateObj = new Date(date)
    return `${dateObj.toLocaleDateString()}`
  }

  const deleteWallet = useCallback(async (walletId: string) => {
    try {
      setOpenModal(false)
      walletsService.deleteUserWallet(walletId)
      setWallets((w) => w.filter(wallet => wallet.wallet_id !== walletId))
    } catch (error) {
      // TODO: handle error
    } finally {
      setWalletToDelete(null)
    }
  }, [walletsService])

  const openModalConfirm = useCallback((walletId: string) => {
    setWalletToDelete(walletId)
    setOpenModal(true)
  }, [])

  const closeModalConfirm = useCallback(() => {
    setWalletToDelete(null)
    setOpenModal(false)
  }, [])

  if (loading) {
    return (
      <div className={`wallets-page text-center box mb-0 loading ${className}`} {...rest}>
        <LoaderComponent className='my-3' size='bigger' />
      </div>
    )
  }

  return (
    <div className={`wallets-page box mb-0 ${className}`} {...rest}>
      <h1>Wallets</h1>
      <p dangerouslySetInnerHTML={{ __html: t('description') as string }} />
      <div className='wrapper-connect'>
        <ButtonComponent width='100%' onClick={() => setOpenModalVinculate(true)}>
          {t('button')} <i className="fa-solid fa-link"></i>
        </ButtonComponent>
      </div>
      {
        !wallets?.length ? (
          <p className='text-center'>{t('empty')}</p>
        ) : (
          <ul>
            {wallets?.map((wallet, index) => (
              <li key={index}>
                <figure>
                  <img
                    className='img-fluid'
                    title={wallet.walletInfo.name}
                    alt={wallet.walletInfo.name}
                    src={parseWalletLogo(wallet.walletInfo.name)}
                  />
                </figure>
                <div className='wrapper-texts'>
                  <h5>
                    {wallet.wallet_id}
                  </h5>
                  <p>
                    {/* <span>
                      {t('balance')}: {wallet.amount} ETH 
                    </span>
                    <span className='separator'>&#x2022;</span> */}
                    <span>{t('date')}: {formatDate(wallet.created_at)}</span>
                  </p>
                </div>
                <div className='actions'>
                  <ButtonComponent onClick={() => openModalConfirm(wallet.wallet_id)} id={`delete-wallet-${wallet.wallet_id}`} title={t('delete') as string} customPadding={'0.25rem 0.563rem'} rounded>
                    <i className="fa-solid fa-trash"></i>
                  </ButtonComponent>
                </div>
              </li>
            ))}
          </ul>
        )
      }
      <ModalVinculateWallet
        open={openModalVinculate}
        onCloseChange={() => setOpenModalVinculate(false)}
        onSuccess={() => onSuccess()}
      />
      <ModalConfirm
        open={openModal}
        onCloseChange={() => closeModalConfirm()}
        onConfirmChange={() => deleteWallet(walletToDelete as string)}
      >
        <p className='my-3'>{t('delete')}</p>
      </ModalConfirm>
    </div>
  )
}