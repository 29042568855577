import ButtonCopy from '@/shared/components/button-copy-text/button-copy-text.component'
import Modal from '@/shared/components/modal/modal.component'
import { HTMLAttributes, PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import './modal-share-nft.scoped.scss'
import './modal-share-nft.scss'

interface Props extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {
  nftId: string | number
  open?: boolean
  onCloseChange?: () => any | void
}

export default ({ nftId, open = false, onCloseChange }: Props) => {
  const [t] = useTranslation('nftFeedComponent')

  if(!nftId) {
    return null
  }

  return (
    <Modal
      open={open}
      header={
        <h5 className='mb-0'>{t('share')}</h5>
      }
      onCloseChange={onCloseChange}
      modalSize='sm'
    >
      <div className='modal-share'>
        <ul className='wrapper-share'>
          <li>
            <a href={`https://api.whatsapp.com/send?text=${t('whatsapp')}: ${encodeURI(`https://app.wallbee.io/nft/${nftId}`)}`} target='_blank'>
              <i className='fab fa-whatsapp'></i>{t('shareTo')} Whatsapp
            </a>
          </li>
          <li>
            <ButtonCopy onlyButton content={`https://app.wallbee.io/nft/${nftId}`}>{t('copy')}</ButtonCopy> 
          </li>
        </ul>
      </div>
    </Modal>
  )
}