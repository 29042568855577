import Button from '@/shared/components/button/button.component'
import HoneycombImageComponent from '@/shared/components/honeycomb-image/honeycomb-image.component'
import InfinityScrollComponent from '@/shared/components/infinity-scroll/infinity-scroll.component'
import LoaderComponent from '@/shared/components/loader/loader.component'
import Modal from '@/shared/components/modal/modal.component'
import VerifiedIconComponent from '@/shared/components/verified-icon/verified-icon.component'
import UserHelper from '@/shared/helpers/user.helper'
import { Nft } from '@/shared/interfaces/nft.interface'
import UserLikesNft from '@/shared/interfaces/user-likes-nft.interface'
import NftService from '@/shared/services/nft.service'
import UserService from '@/shared/services/user.service'
import { HTMLAttributes, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import './modal-likes-nft.component.scoped.scss'

interface Props extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {
  nft: Nft
  open?: boolean
  onCloseChange?: () => any
}

export default ({ nft, open = false, onCloseChange, className = '', ...rest }: Props) => {
  const [tGlobal] = useTranslation()
  const [t] = useTranslation('userProfilePage')
  const [loading, setLoading] = useState(false)
  const nftService = useMemo(() => new NftService(), [])
  const userService = useMemo(() => new UserService(), [])
  const userHelper = useMemo(() => new UserHelper(), [])
  const [loadingScroll, setLoadingScroll] = useState(false)
  const [page, setPage] = useState(0)
  const [hasMore, setHasMore] = useState(true)
  const [list, setList] = useState<UserLikesNft[]>([])
  const [loadingFollow, setLoadingFollow] = useState<any>(-1)
  const PER_PAGE = 30

  const loadList = useCallback(async () => {
    if (page < 1) {
      return
    }
    try {
      const users = await nftService.getNftLikesList(nft.id as string, page, PER_PAGE)
      setList((old: any) => [...old, ...users])
      setHasMore(users?.length >= PER_PAGE)
    } catch (error) {
      // TODO: handle error
      setHasMore(false)
    } finally {
      setLoading(false)
      setLoadingScroll(false)
    }
  }, [page, nftService, nft])

  useEffect(() => {
    if (open) {
      setLoading(true)
      setHasMore(true)
      setList([])
      setPage(1)
    } else {
      setPage(0)
    }
  }, [open])

  useEffect(() => {
    loadList()
  }, [page, loadList])

  const onScroll = useCallback(() => {
    if (loadingScroll || !hasMore || loading) {
      return
    }
    setLoadingScroll(true)
    setPage((p) => {
      const newP = p + 1
      setLoadingScroll(newP !== 1)
      return newP
    })
  }, [hasMore, loading, loadingScroll])

  const handleClose = useCallback(() => {
    setList([])
    setPage(0)
    if (onCloseChange && typeof onCloseChange === 'function') {
      onCloseChange()
    }
  }, [onCloseChange])

  const doFollow = useCallback(async (id: number) => {
    if (id && loadingFollow === -1) {
      try {
        setLoadingFollow(id)
        await userService.putFollowById(id)
        setList((old) => {
          return old.map((u) => {
            if (u.userInfo?.id === id) {
              return {
                ...u,
                following: !u.following
              }
            }
            return u
          })
        })
      } catch (error) {
        // TODO: handle error
      } finally {
        setLoadingFollow(-1)
      }
    }
  }, [loadingFollow, userService])

  let content = <div className={`followers-list my-3 text-center loading ${className}`} {...rest}>
    <LoaderComponent />
  </div>
  if (!loading) {
    content = <div className={`followers-list mb-0 loading ${className}`} {...rest}>
      <InfinityScrollComponent
        loading={loadingScroll}
        onLoadMore={onScroll}
        endMessage={' '}
        hasMore={hasMore}
        elementToScrollId={`wrapper-content-${nft.id}`}
      >
        <div className='wrapper scrollbar' id={`wrapper-content-${nft.id}`}>
          {
            list.length === 0 ? <div className='empty'>
              <p className='m-0'>{t('empty')}</p>
            </div> : null
          }
          {
            list.map((user) => {
              return <div className='item' key={user?.userInfo?.username}>
                <Link to={`/profile/${user?.userInfo?.username}`} onClick={() => handleClose()} className='user'>
                  <span className='wrapper-image'>
                    <HoneycombImageComponent
                      src={userHelper.getImageUrl(user?.userInfo?.id)}
                    />
                  </span>
                  <span className='wrapper-message'>
                    <span className='username'>@{user?.userInfo?.username} {user?.userInfo?.is_verified ? (<VerifiedIconComponent className='ml-1' style={{ fontSize: '90%' }} />) : null}</span>
                    <span className='name'>{user?.userInfo?.name}</span>
                  </span>
                </Link>
                <div className='actions'>
                  <Button
                    size='sm'
                    onClick={() => doFollow(user?.userInfo?.id)}
                    loading={loadingFollow === user?.userInfo?.id}
                    hideChildrenOnLoading
                  >
                    {user?.following ? t('unfollow') : t('follow')}
                  </Button>
                </div>
              </div>
            })
          }
        </div>
      </InfinityScrollComponent>
    </div>
  }

  return (<Modal
    open={open}
    onCloseChange={handleClose}
    header={<h6 className='m-0'>{tGlobal('likes')}</h6>}
    className='modal-likes-nft'
  >
    {content}
  </Modal>)
}