import MainService from './main.service'

export default class CommentsService extends MainService {
  constructor() {
    super()
  }
  async getCommentsByNftId(id: number | string, page = 1, perPage = 15): Promise<any> {
    if (!id) {
      throw new Error('id is required!')
    }
    const headers = await this.getHeaders()
    return await this.http.get(`${this.URL}/comment/nft/${id}?perPage=${perPage}&page=${page}`, { headers }).then((r) => r.data)
  }

  async postCommentsByNftId(id: number | string, comment: any): Promise<any> {
    if (!id) {
      throw new Error('id is required!')
    }
    const headers = await this.getHeaders()
    return await this.http.post(`${this.URL}/comment/nft/${id}`, { comment }, { headers }).then((r) => r.data)
  }

  async deleteCommentsById(id: number | string): Promise<any> {
    if (!id) {
      throw new Error('id is required!')
    }
    const headers = await this.getHeaders()
    return await this.http.delete(`${this.URL}/comment/${id}`, { headers }).then((r) => r.data)
  }

  async patchLikeCommentById(id: number | string): Promise<any> {
    if (!id) {
      throw new Error('id is required!')
    }
    const headers = await this.getHeaders()
    return await this.http.patch(`${this.URL}/comment/${id}/like`, {}, { headers }).then((r) => r.data)
  }
}
