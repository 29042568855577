import HoneycombImageComponent from '@/shared/components/honeycomb-image/honeycomb-image.component'
import ImageNft from '@/shared/components/img-nft/img-nft.component'
import UserHelper from '@/shared/helpers/user.helper'
import { Nft } from '@/shared/interfaces/nft.interface'
import ActionsService from '@/shared/services/actions.service'
import UserService from '@/shared/services/user.service'
import { HTMLAttributes, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import ModalLikesNftComponent from '../modal-likes-nft/modal-likes-nft.component'
import ModalShareNftComponent from '../modal-share-nft/modal-share-nft.component'
import VerifiedIconComponent from '../verified-icon/verified-icon.component'
import NftCommentsComponent from './nft-comments/nft-comments.component'
import './nft-feed.component.scoped.scss'

interface Props extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {
  nft: Nft
  showFullyDescription?: boolean
  commentsPerPage?: number
}

export default ({ nft, showFullyDescription = false, commentsPerPage = 3, className = '', ...rest }: Props) => {
  const [t] = useTranslation('nftFeedComponent')
  const [tGlobal] = useTranslation()
  const userHelper = useMemo(() => new UserHelper(), [])
  const userService = useMemo(() => new UserService(), [])
  const [compressDescription, setCompressDescription] = useState<boolean>(!showFullyDescription)
  const [liked, setLiked] = useState<boolean>(!!nft.liked)
  const [bigHeart, setBigHeart] = useState<boolean>(false)
  const [saved, setSaved] = useState<boolean>(!!nft.saved)
  const actionsService = useMemo(() => new ActionsService(), [])
  const [isVerified, setIsVerified] = useState<boolean>(false)
  const [openModalLikes, setOpenModalLikes] = useState<boolean>(false)
  const [openShareModal, setOpenShareModal] = useState<boolean>(false)

  const focusToComment = useCallback(() => {
    const commentInput = document.getElementById(`comment-insert-${nft.id}`)
    if (commentInput) {
      // there are more than one commentInput in the page with different ids. I need to scroll to the correct one in the center
      commentInput.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
      setTimeout(() => {
        commentInput.focus()
      }, 300)
    }
  }, [nft.id])

  const like = useCallback(async () => {
    await actionsService.nftAction(nft.id, 'likes')
    setLiked((l) => {
      const newVal = !l
      if (newVal) {
        nft.likes++
      }
      if (!newVal) {
        nft.likes--
      }
      return newVal
    })

  }, [nft.id, actionsService, nft.likes])

  const save = useCallback(async () => {
    await actionsService.nftAction(nft.id, 'saves')
    setSaved((s) => !s)
  }, [nft.id, actionsService])

  const onDoubleClick = useCallback(async (e: any) => {
    e.preventDefault()
    setBigHeart(true)
    if (!liked) {
      await like()
    }
    setTimeout(() => {
      setBigHeart(false)
    }, 500)
  }, [like, liked])

  useEffect(() => {
    userService.getUserVerification(nft?.username)
      .then((r) => {
        setIsVerified(r)
      })
  }, [nft?.username, userService])

  return <div className={`nft-feed ${className}`} {...rest}>
    <header>
      <Link to={`/profile/${nft?.username}`}>
        <span className='image-container'>
          <HoneycombImageComponent
            src={userHelper.getImageUrl(nft?.ownerId)}
          />
        </span>
        <span>
          {nft?.username} 
          {isVerified ? (<VerifiedIconComponent className='ml-1' style={{ fontSize: '90%' }} />) : null}
        </span>
      </Link>
    </header>
    <figure className='content' onDoubleClick={(e) => onDoubleClick(e)}>
      <ImageNft nft={nft} />
      <i className={`fa-solid fa-heart ${bigHeart ? 'show' : ''}`} style={{ color: 'var(--primary_color)' }}></i>
    </figure>
    <footer>
      <div className='actions'>
        <button className='action like' onClick={() => like()}>
          <i className={`fa-${liked ? 'solid' : 'regular'} fa-heart`} style={{ color: liked ? 'var(--tertiary_color)' : '' }}></i>
        </button>

        <button className='action comment' onClick={() => focusToComment()}>
          <i className="fa-regular fa-comment"></i>
        </button>

        <button className='action share' onClick={() => setOpenShareModal(true)}>
          <i className="fa-regular fa-share"></i>
        </button>
        
        <button className='action save end' onClick={() => save()}>
          <i className={`fa-${saved ? 'solid gold' : 'regular'} fa-bookmark`}></i>
        </button>
      </div>
      {nft?.likes > 0 ? 
        <div className='wrapper-likes' role='button' tabIndex={0} onClick={() => setOpenModalLikes(true)}>
          <p className='liked'>
            <span>{nft?.likes}</span>
            <span>{tGlobal('likes')}</span>
          </p>
        </div>
      : null}
      <h6 className='mb-1 mt-2'>
        {nft?.name}
      </h6>
      <p className={`description ${compressDescription ? 'compress' : ''}`}>
        <Link to={`/profile/${nft?.username}`}>
          {nft?.username}
        </Link>
        <span>{nft?.metadata?.description}</span>
      </p>
      {
        !showFullyDescription && nft?.metadata?.description?.length && nft.metadata.description.length >= 230 &&
        <button className='see-more-button' onClick={() => setCompressDescription(!compressDescription)}>
          {compressDescription ? tGlobal('more') : tGlobal('less')}
        </button>
      }
      <NftCommentsComponent nft={nft} commentsPerPage={commentsPerPage} />
    </footer>
    <ModalLikesNftComponent nft={nft} open={openModalLikes} onCloseChange={() => setOpenModalLikes(false)} />
    <ModalShareNftComponent
      nftId={nft?.id}
      open={openShareModal}
      onCloseChange={() => setOpenShareModal(false)}
    ></ModalShareNftComponent>
  </div>
}