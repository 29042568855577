import MainService from './main.service'

export default class LoginService extends MainService {
  constructor() {
    super()
  }

  async postLogin(email: string, password: string): Promise<any> {
    return await this.http.post(`${this.URL}/auth`, { email, password }).then((r) => r.data)
  }

  async postResetPassword(email: string) {
    return await this.http.post(`${this.URL}/auth/reset-password`, { email }).then((r) => r.data)
  }

  async postAuthChangePassword(oobCode: string, password: string) {
    return await this.http.post(`${this.URL}/auth/change-password`, { oobCode, password }).then((r) => r.data)
  }

  async resgisterUser(user: any) {
    if (!user) {
      throw new Error('user is required!')
    }
    return await this.http.post(`${this.URL}/user`, user).then((r) => r.data)
  }
}
