import DragDropNft from '@/shared/components/drag-drop-nft/drag-drop-nft.component'
import LoaderComponent from '@/shared/components/loader/loader.component'
import CreateCollectionParamsInterface, { PostCollectionResult } from '@/shared/interfaces/collection.interface'
import NftService from '@/shared/services/nft.service'
import WalletService from '@/shared/services/wallet.service'
import collectionsImg from '@assets/images/png/cards_colections.png'
import polygonImg from '@assets/images/png/polygon.png'
import tooltipInfoImg from '@assets/images/png/tooltip_info.png'
import { ChangeEvent, FormEvent, HTMLAttributes, PropsWithChildren, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { Tooltip } from 'react-tooltip'
import './create-nft.page.scoped.scss'

interface Props extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {

}

// Helper function to convert File[] to FileList
function fileArrayToFileList(files: File[]): FileList {
  const dataTransfer = new DataTransfer()
  files.forEach(file => dataTransfer.items.add(file))
  return dataTransfer.files
}

export default ({ className = '', ...rest }: Props) => {
  const [t] = useTranslation('nftPage')
  const [loading, setLoading] = useState(false)
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [showNftForm, setShowNftForm] = useState(false)
  /**
   * @dev collection data: collection is a group of related NFTs. The following attributes are needed only the collections, not the NFT themselves.
   */
  const [collectionOption, setCollectionOption] = useState('')
  const [collectionType, setCollectionType] = useState<'ERC721' | 'ERC1155'>('ERC721')
  const [collectionSymbol, setCollectionSymbol] = useState<string | null>('')
  const [collectionName, setCollectionName] = useState('')
  const [collectionAddress, setCollectionAddress] = useState<string>('')
  const [collections, setCollections] = useState<{ symbol: string; label: string; address: string, type: 'ERC721' | 'ERC1155' }[]>([])
  const [wallets, setWallets] = useState<{ value: string; label: string }[]>([])
  const [isNewCollection, setIsNewCollection] = useState<boolean>(false)
  const [walletAddress, setWalletAddress] = useState<string>('')
  /**
   * @dev apart from the collection data, the below are nft-specific-related data. Each new nft must have the following below.
   */
  const [nftDescription, setNFTDescription] = useState('')
  const [nftExternalLink, setNFTExternalLink] = useState('')
  const [nftSupply, setNFTSupply] = useState<number>(1) // default value for ERC721 type.
  const [nftImage, setNFTImage] = useState('')
  const [nftId, setNftIdentifier] = useState<number>(0)
  const [network, setNetwork] = useState<boolean>(true)
  const nftService = useMemo(() => new NftService(), [])
  const walletService = useMemo(() => new WalletService(), [])
  const navigate = useNavigate()
  const dragDropNftRef = useRef<{ handleFiles: (files: FileList, isJsonUpload?: boolean) => Promise<void | string> }>(null)

  const handleCollectionExistenceSelection = (e: ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = e.target.value
    setCollectionOption(selectedOption)

    if (selectedOption === '0x') {
      setIsNewCollection(true)
      return
    } else {
      setIsNewCollection(false)
      const selectedCollection = collections.find(item => item.address === selectedOption)
      if (selectedCollection) {
        setCollectionAddress(selectedCollection.address)
        setCollectionName(selectedCollection.label)
        setCollectionSymbol(selectedCollection.symbol)
        setCollectionType(selectedCollection.type)
        setShowNftForm(true)
      }
      return
    }
    return alert('Por favor, recarregue e tente novamente')

  }
  const handleCollectionSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    // Validate collection data
    if (!collectionName) return alert('Digite o nome da coleção!')
    if (!collectionType) return alert('Escolha a categoria da coleção!')
    if (collectionType === 'ERC721' && !collectionSymbol) return alert('Insira o símbolo da coleção!')
    const payload = {
      // Combine collection and NFT data
      collectionName,
      nftDescription,
      nftExternalLink,
      collectionType,
      collectionSymbol: collectionType === 'ERC721' ? collectionSymbol : null,
      nftSupply,
      nftImage,
    }

    if (collectionOption === '0x') {
      setCollectionOption(collectionName)
      setIsNewCollection(true)
    }
    // Show NFT form
    setShowNftForm(true)
  }

  const handleNftSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const updatedNftSupply = collectionType === 'ERC721' ? 1 : nftSupply
    setNFTSupply(updatedNftSupply)

    if (nftSupply == 0 || Number.isNaN(nftSupply)) return alert('Insira a quantidade da NFT corretamente!')
    if (!nftImage) return alert('Insira a imagem da sua NFT!')
    if (Number.isNaN(nftId)) return alert('Insira o identificador da NFT corretamente!')
    if (!nftDescription) return alert('Digite a descrição da coleção!')
    if (!nftExternalLink) return alert('Insira uma imagem para a coleção!')
    if (!isNewCollection && collectionAddress == '') return alert('Escolha novamente sua coleção!')
    if (!walletAddress) return alert('Escolha sua wallet!')

    setLoadingSubmit(true)

    // Create the tokenURI JSON
    const tokenURIData = {
      name: collectionName,
      description: nftDescription,
      external_url: nftExternalLink,
      image: nftImage,
      // Add any other properties you need for the tokenURI
    }

    // Convert the tokenURI data to a JSON string
    const tokenURIJson = JSON.stringify(tokenURIData)

    // Create a new Blob with the JSON string
    const tokenURIBlob = new Blob([tokenURIJson], { type: 'application/json' })

    /**
     * @dev the below uses always the same name for the .json nft metadata file. There's not problem with that since the tokenURILink file is generated by the content of the file, which is very unlikely that all fields in the file will be equal for different users, specially when we consider the nft image.
     */
    // convert File[] to FileList
    const fileList = fileArrayToFileList([new File([tokenURIBlob], 'nft-metadata.json')])

    // Call the handleFiles function from the DragDropNft component to upload the tokenURI
    if (dragDropNftRef.current) {
      try {
        const tokenURILink = await dragDropNftRef.current.handleFiles(fileList, true)
        if (!tokenURILink) throw new Error('Something unexpected happened. Please try again!')

        // Now that we have updated the tokenURI, let's create the payload
        const payload = {
          collectionType,
          collectionName,
          collectionSymbol: collectionType === 'ERC721' ? collectionSymbol : null,
          tokenURI: tokenURILink,
          tokenID: nftId,
          tokenAmount: nftSupply,
          newCollection: isNewCollection,
          walletAddress,
          collectionAddress: isNewCollection ? null : collectionOption,
          nftDescription,
          nftExternalLink,
          nftImage,
          /**
           * @dev below is true only because the minting of this NFT is done through WB Factory contract. Only the NFTs minted through the WB Factory are able to be sold|bought in the platform, since we have their approvals without the user having to perform any on-chain action him/herself.
           */
          isWallBeeNft: true
        }
        console.log({ payload })
        // make req to back-end
        try {
          const answer: PostCollectionResult | undefined = await nftService.postCollection(payload as CreateCollectionParamsInterface)

          // TODO: Do some front-end magic to show this to the user.
          // something like a modal with a link to `https://polygonscan.com/tx/${answer?.transactionHash}`
          console.log('txHash', answer?.transactionHash)
        } catch (err) {
          alert('Alguma coisa deu errado. Por favor, tente novamente')
        }
      } catch (err) {
        alert('Alguma coisa deu errado com sua imagem. Por favor, tente novamente')
      }
    }
    // -----------

    setLoadingSubmit(false)
  }

  useEffect(() => {
    const fetchCollections = async () => {
      try {
        const fetchedCollections = await nftService.getCollectionsForUser(true)
        const formattedCollections = fetchedCollections.map((collection: any) => ({
          symbol: collection.collectionSymbol,
          label: collection.collectionName,
          address: collection.collectionAddress,
          type: collection.collectionType
        }))
        setCollections([{ address: '', label: 'Escolha uma coleção' }, { address: '0x', label: 'Criar nova coleção' }, ...formattedCollections])
      } catch (error) {
        console.error('Error fetching collections:', error)
      }
    }

    const fetchWallets = async () => {
      try {
        const fetchedWallets = await walletService.getUserWallets()
        const formattedWallets = fetchedWallets.map((wallet: any) => ({
          value: wallet.wallet_id,
          // label: wallet.walletInfo.name,
          label: `${wallet.walletInfo.name} ${wallet.wallet_id}`
        }))
        setWallets(formattedWallets)
        // Set the wallet address to the first wallet by default
        setWalletAddress(formattedWallets[0].value)
      } catch (error) {
        console.error('Error fetching wallets:', error)
      }
    }

    fetchCollections()
    fetchWallets()
  }, [nftService, walletService])


  if (loading) {
    return (
      <div className={`nft-page py-3 container loader-wrapper ${className}`}>
        <LoaderComponent size='big' />
      </div>
    )
  }

  return <div className={`create-nft-page box ${className}`} {...rest}>
    <div>
      <div className='grid'>
        <h1>
          Criar NFT
        </h1>
        <p className='description-text'>
          Uma vez que sua arte é registrada não será possivel fazer qualquer alteração.
        </p>
      </div>
      <div className='inplace flex'>
        <div className='grid small-full large-half'>
          <div>
            <DragDropNft onImageDone={(e) => setNFTImage(e)} ref={dragDropNftRef} />
          </div>
          <div className='mt-6'>
            <div className={`current-network ${network ? 'active' : ''}`}>
              <div onClick={() => setNetwork(!network)} role="presentation">
                <img src={polygonImg} alt="Polygon logo" />
                <p>
                  Polygon
                </p>
              </div>
            </div>
          </div>
        </div>
        {!showNftForm && (<div className='grid small-full large-half'>
          <div>
            <h4>
              <form onSubmit={e => handleCollectionSubmit(e)} className='collection-form'>
                <div>
                  {/* good space for wallets */}
                  <label htmlFor="">
                    Coleção
                  </label>
                  <div className='collection-card-select'>
                    <img src={collectionsImg} alt="Collections png" />
                    <select name="collection-options" id="collection-options" value={collectionOption} onChange={(e) => handleCollectionExistenceSelection(e)}>
                      {
                        collections.map((item) => <option key={item.address} value={item.address}>{item.label}</option>)
                      }
                    </select>
                  </div>
                  <span className='tip-text'>
                    vincule sua NFT a uma coleção ou crie uma nova coleção
                  </span>
                  {
                    collectionOption === '0x' && (
                      <>
                        <div>
                          <label htmlFor="name" className='labelForm'>
                            <p> Nome da Coleção* </p>
                            <div className='tooltipz'>
                              <img data-tooltip-id="collection-name" data-tooltip-content="Qual o nome que representa sua coleção de NFTs? Uma coleção é um conglomerado de NFTs cujo propósito é o mesmo. Alguns exemplos famosos de nomes são: CryptoPunks, Bored Ape Yacht Club, Azuki." src={tooltipInfoImg} alt="Tooltip info png" />
                            </div>
                            <Tooltip id="collection-name" />
                          </label>
                          <input type="text" id='name' name='name' placeholder='Nome' onChange={(e) => setCollectionName(e.target.value)} />
                        </div>
                        <div>
                          <label htmlFor="collectionType" className='labelForm'>
                            <p>Categoria da Coleção*</p>
                            <div className='tooltipz' data-tip="Select the type of collection you want to create: ERC721 (unique NFTs) or ERC1155 (multiple copies of NFTs)">
                              <img data-tooltip-id="collection-type" data-tooltip-content="A sua coleção será uma coleção de NFTs únicas ou será uma coleção de NFTs com cópias? As mais famosas NFTs são únicas, por isso o seu valor alto (apenas um dono por vez). As NFTs com cópias são geralmente utilizadas como artefatos de um jogo de NFT, pois podem ter vários donos simultaneamente. Uma coleção de categoria única pode apenas conter NFTs únicas." src={tooltipInfoImg} alt="Tooltip info png" />
                            </div>
                            <Tooltip id="collection-type" />
                          </label>
                          <select id="collectionType" value={collectionType} onChange={(e) => setCollectionType(e.target.value as 'ERC721' | 'ERC1155')}>
                            <option value="ERC721">Única</option>
                            <option value="ERC1155">Cópias</option>
                          </select>
                          {collectionType === 'ERC721' && (
                            <>
                              <div>
                                <label htmlFor="collectionSymbol" className='labelForm'>
                                  <p>Símbolo da Coleção*</p>
                                  <div className='tooltipz' >
                                    <img data-tooltip-id="collection-symbol" data-tooltip-content="Qual o símbolo que representará sua coleção? Coleções famosas possuem os símbolos BAYC, MAYC, AZUKI, COOL. Símbolos geralmente são caixa alta." src={tooltipInfoImg} alt="Tooltip info png" />
                                  </div>
                                  <Tooltip id="collection-symbol" />
                                </label>
                                <input type="text" id="collectionSymbol" name="collectionSymbol" placeholder="WBNFT" onChange={(e) => setCollectionSymbol(e.target.value)} />
                              </div>
                            </>
                          )}
                        </div>
                        {collectionType === 'ERC1155' && (
                          <>
                          </>
                        )}
                        <div className='actions'>
                          <button type="submit" disabled={loadingSubmit}>
                            {loadingSubmit ? 'Carregando...' : 'Próximo'}
                          </button>
                        </div>
                        {/* now show the Nft form */}

                      </>
                    )
                  }
                </div>
              </form>
            </h4>
          </div>
        </div>)}
        {showNftForm && (<div className='grid small-full large-half'>
          <div>
            <h4>
              <form onSubmit={e => handleNftSubmit(e)} className='collection-form'>
                {/*<form onSubmit={showNftForm ? handleNftSubmit : handleCollectionSubmit} className='collection-form'>*/}
                <div>
                  <label htmlFor="">
                    NFT
                  </label>
                  <div className='collection-card-select'>
                    <img src={collectionsImg} alt="Collections png" />
                    <div className="selected-collection">
                      <p>Coleção: {collectionName}</p>
                    </div>
                  </div>
                  <span className='tip-text'>
                    Sua NFT será criada dentro da sua coleção: {collectionName}
                  </span>
                  <div>
                    <label htmlFor="wallet-options" className='labelForm'>
                      <p> Wallet* </p>
                      <div className='tooltipz'>
                        <select name="wallet-options" id="wallet-options" value={walletAddress} onChange={(e) => setWalletAddress(e.target.value)}>
                          {
                            wallets.map((item) => <option key={item.value} value={item.value}>{item.label}</option>)
                          }
                        </select>
                        <img data-tooltip-id="wallet" data-tooltip-content="Para qual das suas carteiras a sua NFT será criada?" src={tooltipInfoImg} alt="Tooltip info png" />
                      </div>
                      <Tooltip id="wallet" />
                    </label>
                  </div>
                  {
                    <>
                      <div>
                        {collectionType === 'ERC721' && (
                          <>
                            <div>
                              <label htmlFor="description" className='labelForm'>
                                <p > Descrição da NFT* </p>
                                <div >
                                  <img data-tooltip-id="nft-description" data-tooltip-content="Descreva a NFT que você criará: o que e quem ela representa, qual o valor que ela traz consigo. Expresse sua criação!" src={tooltipInfoImg} alt="Tooltip info png" />
                                </div>
                                <Tooltip id="nft-description" />
                              </label>
                              <textarea rows={4} id='description' name='description' placeholder='Descrição' onChange={
                                (e) => setNFTDescription(e.target.value)
                              } />
                            </div>
                            <div>
                              <label htmlFor="link" className='labelForm'>
                                <p> Link Externo da NFT* </p>
                                <div className='tooltipz'>
                                  <img data-tooltip-id="nft-external-link" data-tooltip-content="Onde os visualizadores da sua NFT podem te encontrar ou encontrar o seu projeto? Esse link fica disponível com sua NFT nos marketplaces a fim de qualquer usuário saber sua origem. Geralmente sites do projeto da NFT, do blog do autor ou até de suas mídias sociais é um link que costuma ser usado." src={tooltipInfoImg} alt="Tooltip info png" />
                                </div>
                                <Tooltip id="nft-external-link" />
                              </label>
                              <input type="text" id='link' name='link' placeholder='https://myblog.com/' onChange={(e) => setNFTExternalLink(e.target.value)} />
                            </div>
                            <div>
                              <label htmlFor="identifier" className='labelForm'>
                                <p> Número Identificador da NFT* </p>
                                <div className='tooltipz'>
                                  <img data-tooltip-id="nft-id" data-tooltip-content="Identificador único de uma NFT dentro de uma coleção. O par formado pelo identificador da NFT e a coleção fazem da NFT um ativo único. Não pode haver identificadores iguais para NFTs dentro de uma mesma coleção. Tecnicamente falando, este é o id do token NFT." src={tooltipInfoImg} alt="Tooltip info png" />
                                </div>
                                <Tooltip id="nft-id" />
                                <input type="text" id='identifier' name='identifier' placeholder='(qualquer número)' onChange={(e) => setNftIdentifier(Number(e.target.value))} />
                              </label>
                            </div>
                          </>
                        )}
                      </div>
                      {collectionType === 'ERC1155' && (
                        <>
                          <div>
                            <label htmlFor="description" className='labelForm'>
                              <p > Descrição da NFT* </p>
                              <div >
                                <img data-tooltip-id="nft-description" data-tooltip-content="Descreva a NFT que você criará: o que e quem ela representa, qual o valor que ela traz consigo. Expresse sua criação!" src={tooltipInfoImg} alt="Tooltip info png" />
                              </div>
                              <Tooltip id="nft-description" />
                            </label>
                            <textarea rows={4} id='description' name='description' placeholder='Descrição' onChange={(e) => setNFTDescription(e.target.value)} />
                          </div>
                          <div>
                            <label htmlFor="link" className='labelForm'>
                              <p> Link Externo da NFT* </p>
                              <div className='tooltipz'>
                                <img data-tooltip-id="nft-external-link" data-tooltip-content="Onde os visualizadores da sua NFT podem te encontrar ou encontrar o seu projeto? Esse link fica disponível com sua NFT nos marketplaces a fim de qualquer usuário saber sua origem. Geralmente sites do projeto da NFT, do blog do autor ou até de suas mídias sociais é um link que costuma ser usado." src={tooltipInfoImg} alt="Tooltip info png" />
                              </div>
                              <Tooltip id="nft-external-link" />
                            </label>
                            <input type="text" id='link' name='link' placeholder='https://myblog.com/' onChange={(e) => setNFTExternalLink(e.target.value)} />
                          </div>
                          <div>
                            <label htmlFor="identifier" className='labelForm'>
                              <p> Número Identificador da NFT* </p>
                              <div className='tooltipz'>
                                <img data-tooltip-id="nft-id" data-tooltip-content="Identificador único de uma NFT dentro de uma coleção. O par formado pelo identificador da NFT e a coleção fazem da NFT um ativo único. Não pode haver identificadores iguais para NFTs dentro de uma mesma coleção. Tecnicamente falando, este é o id do token NFT." src={tooltipInfoImg} alt="Tooltip info png" />
                              </div>
                              <Tooltip id="nft-id" />
                              <input type="text" id='nft-id' name='identifier' placeholder='(qualquer número)' onChange={(e) => setNftIdentifier(Number(e.target.value))} />
                            </label>
                          </div>
                          <div>
                            <label htmlFor="amount" className='labelForm'>
                              <p> Cópias da NFT* </p>
                              <div className='tooltipz'>
                                <img data-tooltip-id="nft-amount" data-tooltip-content="Uma NFT com várias cópias pode ser de várias donos simultaneamente, enquanto que uma NFT única pertence a apenas um único dono por vez. Quantas cópias você gostaria?" src={tooltipInfoImg} alt="Tooltip info png" />
                              </div>
                              <Tooltip id="nft-amount" />
                            </label>
                            <input type="text" id='amount' name='amount' placeholder='2,3,19738123718937 (quantas quiser)' onChange={(e) => setNFTSupply(Number(e.target.value))} />
                          </div>
                        </>
                      )}
                      <div className='actions'>
                        <button type='button' onClick={() => setShowNftForm(false)}>
                          Voltar
                        </button>
                        <button type="submit" disabled={loadingSubmit}>
                          {loadingSubmit ? 'Carregando...' : 'Criar Coleção e NFT na Blockchain'}
                        </button>
                      </div>

                    </>
                  }
                </div>
              </form>
            </h4>
          </div>
        </div>
        )}

      </div>
    </div>
  </div>
}