import UserHelper from '@/shared/helpers/user.helper'
import InvitesService from '@/shared/services/invites.service'
import Loader from '@components/loader/loader.component'
import { HTMLAttributes, PropsWithChildren, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router'
import './blank.page.scoped.scss'

interface Props extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {

}

export default ({ className = '', ...rest }: Props) => {
  const invitesService = useMemo(() => new InvitesService(), [])
  const navigate = useNavigate()
  const userHelper = useMemo(() => new UserHelper(), [])

  useEffect(() => {
    (async () => {
      const invite = await invitesService.postInvitePublic()
      await userHelper.logout()
      navigate(`/login/register?inviteCode=${invite.shortcode}`)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitesService, userHelper])

  return (
    <div className={`blank-page ${className}`} {...rest}>
      <Loader size='bigger' />
    </div>
  )
}