import { HTMLAttributes, PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import './not-found.page.scoped.scss'

interface Props extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {

}

export default ({ className = '', ...rest }: Props) => {
  const [t] = useTranslation('notFoundPage')

  return <div className={`not-found-page ${className}`} {...rest}>
    <h1>
      {t('title')}
    </h1>
    <p>
      {t('p')}
    </p>
  </div>
}