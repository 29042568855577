import { getAnalytics } from 'firebase/analytics'
import { initializeApp } from 'firebase/app'
import { getPerformance } from 'firebase/performance'
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import './i18n'
import './index.scss'
import reportWebVitals from './reportWebVitals'
import firebaseObservable from './shared/observables/firebase.observable'

const app = initializeApp({
  'apiKey': process.env.REACT_APP_ENV_FIREBASE_API_KEY,
  'authDomain': process.env.REACT_APP_ENV_FIREBASE_AUTH_DOMAIN,
  'projectId': process.env.REACT_APP_ENV_FIREBASE_PROJECT_ID,
  'storageBucket': process.env.REACT_APP_ENV_FIREBASE_STORAGE_BUCKET,
  'messagingSenderId': process.env.REACT_APP_ENV_FIREBASE_MESSAGING_SENDER_ID,
  'appId': process.env.REACT_APP_ENV_FIREBASE_APP_ID,
  'measurementId': process.env.REACT_APP_ENV_FIREBASE_MEASUREMENT_ID,
})
// firebase Analytics
const analytics = getAnalytics(app)
const performance = getPerformance(app)
firebaseObservable.next({ analytics, performance, app })
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
