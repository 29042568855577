import ButtonCopyTextComponent from '@/shared/components/button-copy-text/button-copy-text.component'
import InfinityScroll from '@/shared/components/infinity-scroll/infinity-scroll.component'
import LoaderComponent from '@/shared/components/loader/loader.component'
import { Invite } from '@/shared/interfaces/invites-list.interface'
import { User } from '@/shared/interfaces/user.interface'
import userObservable from '@/shared/observables/user.observable'
import InvitesService from '@/shared/services/invites.service'
import { HTMLAttributes, PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Subscription } from 'rxjs'
import './avaliable.page.scoped.scss'

interface Props extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {

}

export default ({ className = '', ...rest }: Props) => {
  const [t] = useTranslation('invitesPage')
  const onUserChangeSubscription = useRef<Subscription>()
  const [user, setUser] = useState<User | null>()
  const [loading, setLoading] = useState<boolean>(true)
  const [loadingPage, setLoadingPage] = useState<boolean>(false)
  const [page, setPage] = useState<number>(1)
  const [hasMore, setHasMore] = useState<boolean>(true)
  const invitesService = useMemo(() => new InvitesService(), [])
  const [invitesAvaliable, setInvitesAvaliable] = useState<Invite[]>([])
  const PER_PAGE = 15
  const URL = `${window.location.origin}/login/register?inviteCode=`

  const getInvites = useCallback(async () => {
    let empty = false
    try {
      const invites = await invitesService.getInvitesByStatus('available', page, PER_PAGE)
      setInvitesAvaliable((i) => i.concat(invites?.data))
      empty = !invites?.data?.length && page === 1
      if (invites?.data?.length < PER_PAGE) {
        setHasMore(false)
      }
      if (empty) {
        await invitesService.postInviteByAuty()
        setHasMore(true)
      }
    } catch (error) {
      // TODO: handle error
    } finally {
      if (!empty) {
        setLoadingPage(false)
        setLoading(false)
      }
      if (empty) {
        getInvites()
      }
    }
  }, [invitesService, page])

  useEffect(() => {
    onUserChangeSubscription.current = userObservable.subscribe((u) => {
      return setUser(u)
    })

    return () => {
      onUserChangeSubscription.current?.unsubscribe()
    }
  }, [])

  useEffect(() => {
    if (!user) {
      return
    }
    setLoading(true)
    setInvitesAvaliable([])
    setPage(1)
  }, [user])

  useEffect(() => {
    setLoadingPage(true)
    getInvites()
  }, [getInvites, page])

  const toWhatsappLink = (invite: string) => {
    const message = t('evaluablePage.whatsappMessage', { name: user?.name }) + `: ${URL}${invite}`
    window.open(`https://api.whatsapp.com/send?text=${message}`, '_blank')
  }

  if (loading) {
    return <div className={`avaliable-page my-5 text-center loader-wrapper ${className}`}>
      <LoaderComponent size='big' />
    </div>
  }

  if (!invitesAvaliable.length && !loading) {
    return <div className={`avaliable-page ${className}`}>
      <h6 className='mt-5 text-center'>
        {t('evaluablePage.noInvites')}
      </h6>
    </div>
  }

  return <div className={`avaliable-page ${className}`} {...rest}>
    <h6 className='mt-5 text-center'>
      {t('evaluablePage.description')}
    </h6>
    <InfinityScroll loading={loadingPage} onLoadMore={() => setPage((p) => p + 1)} hasMore={hasMore}>
      <ul className='list'>
        {invitesAvaliable.map((invite, i) =>
          <li key={`${invite.id}-${i}`}>
            <ButtonCopyTextComponent
              content={`${URL}${invite?.shortcode}`}
            >
              <button className='whats-button' onClick={() => toWhatsappLink(invite?.shortcode)}>
                <i className="fa-brands fa-whatsapp green"></i>
              </button>
            </ButtonCopyTextComponent>
          </li>
        )}
      </ul>
    </InfinityScroll>
  </div>
}