import LanguageSelector from '@/shared/components/language-selector/language-selector'
import Logo from '@/shared/components/logo/logo.component'
import OnBoardingComponent from '@/shared/components/on-boarding/on-boarding.component'
import { USER_KEY } from '@/shared/services/main.service'
import localForageHelper from '@helpers/localforage.helper'
import ForgotPasswordPage from '@modules/login/pages/forgot-password/forgot-password.page'
import LoginPage from '@modules/login/pages/login/login.page'
import RegisterPage from '@modules/login/pages/register/register.page'
import ResetPasswordPage from '@modules/login/pages/reset-password/reset-password.page'
import { HTMLAttributes, PropsWithChildren, useEffect, useState } from 'react'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import './login.layout.scoped.scss'

interface Props extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {

}

export default ({ className = '', ...rest }: Props) => {
  const navigate = useNavigate()
  const [showOnboarding, setShowOnboarding] = useState(false)
  const ONBOARDING_KEY = 'onboarding-pass'
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    localForageHelper.getItem(ONBOARDING_KEY).then((onboardingPass: any) => {
      if (!onboardingPass && !window.location.pathname.includes('reset-password')) {
        setShowOnboarding(true)
        localForageHelper.setItem(ONBOARDING_KEY, true)
      }
    })

    localForageHelper.getItem(USER_KEY).then((userObject: any) => {
      localForageHelper.setItem(ONBOARDING_KEY, true)
      setLoading(false)
      if (userObject && !window.location.pathname.includes('reset-password')) {
        navigate('/feed')
      }
    })
  }, [navigate])

  if (loading) {
    return null
  }

  return <div className={`login-layout ${className}`} {...rest}>
    <OnBoardingComponent show={showOnboarding} onClose={() => setShowOnboarding(false)} />
    <header>
      <Logo className='main-logo' />
    </header>
    <main>
      <Routes>
        <Route index element={<LoginPage />} />
        <Route path='register' element={<RegisterPage />} />
        <Route path='forgot-password' element={<ForgotPasswordPage />} />
        <Route path='reset-password' element={<ResetPasswordPage />} />
        <Route path="*" element={<Navigate to={{ pathname: '/error/not-found' }} />} />
      </Routes>
    </main>
    <footer>
      <LanguageSelector />
    </footer>
  </div>
}