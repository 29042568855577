import ImageNft from '@/shared/components/img-nft/img-nft.component'
import { Nft } from '@/shared/interfaces/nft.interface'
import { HTMLAttributes, PropsWithChildren, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import './nft-grid-feed.component.scoped.scss'

interface Props extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {
  nft: Nft
  hidden?: boolean
  onHidden?: (event: any) => any
}

export default ({ nft, className = '', hidden, onHidden, ...rest }: Props) => {
  const [isSelected, setIsSelected] = useState(false)

  useEffect(() => {
    setIsSelected(false)
  }, [hidden])

  const handleHidden = () => {
    if (onHidden && typeof onHidden === 'function') {
      onHidden(nft.id)
    }
    setIsSelected(!isSelected)
  }

  if (hidden) {
    return (
      <button
        className={`item button-select ${isSelected ? 'selected' : ''}`}
        onClick={handleHidden}
        role="checkbox"
        aria-checked={isSelected}
      >
        <figure className='image'>
          <ImageNft nft={nft} />
          <span style={
            {
              opacity: isSelected ? '1' : '0'
            }
          } className='hover'>
            <span className={`icon ${isSelected ? 'green icon-select' : 'white'}`}>
              <i className="fa-solid fa-check"></i>
            </span>
          </span>
        </figure>
      </button>
    )
  }

  return (
    <Link to={`/nft/${nft.id}`} className='item' key={nft.id}>
      <figure className='image' key={nft.id}>
        <ImageNft nft={nft} />
        <span className='hover'>
          <span className='icon'>
            <i className="fa-solid fa-heart"></i> {nft?.likes || 0}
          </span>
          <span className='icon'>
            <i className="fa-solid fa-comment"></i> {nft?.comments || 0}
          </span>
        </span>
      </figure>
    </Link >
  )
}