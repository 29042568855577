// import logo from public
import React from 'react'
import './logo.component.scss'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  forceNegative?: boolean
  forceNormal?: boolean
  width?: string
  height?: string
  styleImg?: any
}

const LOGO_PATH = '/logo.png'
const LOGO_NEGATIVE_PATH = '/logo_negative.png'

export default ({
  className = '',
  forceNegative,
  forceNormal,
  width = '',
  height = '',
  style = {},
  styleImg,
  id = '',
}: Props) => {

  return (
    <figure className={`${className} beta`} id={id} style={style}>
      <img
        width={width}
        height={height}
        src={LOGO_PATH}
        alt="Wallbee"
        title="Wallbee"
        className={`img-fluid normal ${forceNormal ? 'display-inline-block' : ''}`}
        style={{
          width: width,
          height: height,
          ...styleImg,
        }}
      />
      <img
        width={width}
        height={height}
        src={LOGO_NEGATIVE_PATH}
        alt="Wallbee"
        title="Wallbee"
        className={`img-fluid negative ${forceNegative ? 'display-inline-block' : ''}`}
        style={{
          width: width,
          height: height,
          ...styleImg,
        }}
      />
    </figure>
  )
}
