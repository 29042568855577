import CoinBaseLogo from '@/assets/images/png/coinbase-logo.png'
import EthLogo from '@/assets/images/png/eth-logo.png'
import MetaMaskLogo from '@/assets/images/png/metamask-logo.png'
import TrustLogo from '@/assets/images/png/trust-logo.webp'
import Button from '@/shared/components/button/button.component'
import LoaderComponent from '@/shared/components/loader/loader.component'
import Modal from '@/shared/components/modal/modal.component'
import ToastHelper from '@/shared/helpers/toast.helper'
import { Wallet } from '@/shared/interfaces/wallets.interface'
import WalletService from '@/shared/services/wallet.service'
import { ethers } from 'ethers'
import { HTMLAttributes, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import './modal-vinculate-wallet.component.scoped.scss'
const isProduction = process.env.REACT_APP_ENV_NAME === 'production'
declare let window:any

declare module 'ethers' {
  
}

interface Props extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {
  open?: boolean
  onCloseChange?: () => any
  onSuccess?: () => any
}

export default ({ open = false, onCloseChange, onSuccess, className = '', ...rest }: Props) => {
  const [t] = useTranslation('walletsPage')
  const [walletsList, setWalletsList] = useState<Wallet[]>([])
  const walletsService = useMemo(() => new WalletService(), [])
  const [loading, setLoading] = useState(false)
  const toast = new ToastHelper().toast

  const handleSuccess = useCallback(() => {
    if (onSuccess && typeof onSuccess === 'function') {
      onSuccess()
    }
  }, [onSuccess])

  const handleErrors = useCallback((error = 'unexpectedError') => {
    switch (error) {
      case 'DUPLICATE_WALLET_FOR_USER':
        return t('modal.errors.duplicate')
      default:
        return t('modal.errors.unexpectedError')
    }
  }, [t])

  const parseWalletLogo = useCallback((walletName: string) => {
    switch (walletName) {
      case 'Coinbase Wallet':
        return CoinBaseLogo
      case 'MetaMask':
        return MetaMaskLogo
      case 'Trust Wallet':
        return TrustLogo
      default:
        return EthLogo
    }
  }, [])

  const getWindowProvider = useCallback((name: string) => {
    switch (name) {
      case 'Coinbase Wallet':
        if (window?.ethereum?.isCoinbaseWallet) {
          return window?.ethereum
        }
        return window?.ethereum?.providerMap?.get('CoinbaseWallet')
      case 'MetaMask':
        if (window?.ethereum?.isMetaMask) {
          return window?.ethereum
        }
        return window?.ethereum?.providerMap?.get('MetaMask')
      case 'Trust Wallet':
        if (window?.ethereum?.isTrust) {
          return window?.ethereum
        }
        return window?.ethereum?.providerMap?.get('Trust')
      default:
        if (window?.ethereum) {
          if (window?.ethereum?.providerMap) {
            // get first provider
            const providers = window?.ethereum?.providerMap?.values()
            const provider = providers?.next()?.value
            if (provider) {
              return provider
            }
          }
          return window?.ethereum
        }
        return null
    }
  }, [])

  const openWallet = useCallback(async (name: string, id: number) => {
    try {
      const windowProvider = getWindowProvider(name)
      if (!windowProvider) {
        if (name === 'MetaMask') {
          window.open(`https://metamask.app.link/dapp/${!isProduction ? 'stg-' : ''}app.wallbee.io/wallets`, '_blank')
          return
        }
        if (name === 'Coinbase Wallet') {
          window.open(`https://go.cb-w.com/dapp?cb_url=${!isProduction ? 'stg-' : ''}app.wallbee.io%2Fwallets`, '_blank')
          return
        }
        if (name === 'Trust Wallet') {
          window.open(`https://link.trustwallet.com/open_url?coin_id=60&url=https://${!isProduction ? 'stg-' : ''}app.wallbee.io/wallets`, '_blank')
          return
        }
        toast.error(t(`modal.errors.${name}`), {
          closeButton: true,
          className: 'full',
          position: `${window.innerWidth > 991 ? 'top' : 'bottom'} center`,
          duration: 3600000,
          notOverClick: true,
          immediately: true,
        })
        return
      }

      const provider = new ethers.BrowserProvider(windowProvider)  
      const account = await provider.getSigner()

      if (!account?.address) {
        toast.error(t(`modal.errors.${name}`), {
          closeButton: true,
          className: 'full',
          position: `${window.innerWidth > 991 ? 'top' : 'bottom'} center`,
          duration: 3600000,
          notOverClick: true,
          immediately: true,
        })
        return
      }

      setLoading(true)
  
      await walletsService.postWallet([{
        wallet_id: account?.address,
        wallet_brand: id,
        ammount: 0
      }])

      toast.success(t('modal.success'), {
        closeButton: true,
        className: 'full',
        position: `${window.innerWidth > 991 ? 'top' : 'bottom'} center`,
        notOverClick: true,
        immediately: true,
      })
      handleSuccess()
    } catch (error: any) {
      toast.error(handleErrors(error?.response?.data?.errorCode), {
        closeButton: true,
        className: 'full',
        position: `${window.innerWidth > 991 ? 'top' : 'bottom'} center`,
        duration: 3600000,
        notOverClick: true,
        immediately: true,
      })
    } finally {
      setLoading(false)
    }
  }, [t, toast, getWindowProvider, walletsService, handleErrors, handleSuccess])

  useEffect(() => {
    const getWallets = async () => {
      try {
        const wallets = await walletsService.getWalletsList()
        setWalletsList(wallets)
      } catch (error) {
        // TODO: handle error
      }
    }
    getWallets()
  }, [walletsService])

  let content = (
    <div className='content text-center'>
      <LoaderComponent size='big' style={{ margin: '2rem auto' }} />
    </div>
  )
  if (!loading) {
    content = (
      <div className='content'>
      <p className='mt-3'>{t('modal.text')}:</p>
      {walletsList.map((wallet, index) => (
        <Button key={index} borderColor='var(--text_color)' color='var(--background)' textColor='var(--text_color)' onClick={() => openWallet(wallet.name, wallet.id)}>
          <span className='wallet-item'>
            <figure>
              <img
                className='img-fluid'
                title={wallet.name}
                alt={wallet.name}
                src={parseWalletLogo(wallet.name)}
              />
              
            </figure>
            <p>{wallet.name}</p>
          </span>
        </Button>
      ))}
      <p className='mb-3' dangerouslySetInnerHTML={{ __html: t('modal.footer') as string }}></p>
    </div>
    )
  }

  return (<Modal
    open={open}
    onCloseChange={onCloseChange}
    header={<h6 className='m-0'>{t('modal.title')}</h6>}
    className={`modal-confirm ${className}`}
    {...rest}
  >
    {content} 
  </Modal>) 
}