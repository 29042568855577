import MainService from './main.service'

export default class WalletService extends MainService {
  constructor() {
    super()
  }

  async postWallet(wallets: any): Promise<any> {
    if (!wallets) {
      throw new Error('wallet is required!')
    }
    const headers = await this.getHeaders()
    return await this.http.post(`${this.URL}/wallet/user/add`, { wallets }, { headers }).then((r) => r.data)
  }

  async getWalletsList() {
    const headers = await this.getHeaders()
    return await this.http.get(`${this.URL}/wallets`, { headers }).then((r) => r.data)
  }

  async getUserWallets() {
    const headers = await this.getHeaders()
    return await this.http.get(`${this.URL}/wallets/user`, { headers }).then((r) => r.data)
  }

  async deleteUserWallet(walletId: string) {
    const headers = await this.getHeaders()
    return await this.http.delete(`${this.URL}/wallets/user/${walletId}`, { headers }).then((r) => r.data)
  }
}
