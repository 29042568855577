import defaultAvatar from '@assets/images/jpg/avatar.jpg'
import React from 'react'
import './honeycomb-image.component.scoped.scss'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  src?: string
  altImage?: string
  titleImage?: string
  borderColor?: string
  onClick?: (event: any) => any
}

export default ({ src, altImage = 'Avatar', titleImage = 'Avatar', borderColor = '', onClick, ...rest }: Props) => {

  const handleClick = (event: any) => {
    if (onClick && typeof onClick === 'function') {
      onClick(event)
    }
  }

  return (
    <div
      className={`honeycomb-image-container ${onClick ? 'clickable' : ''}`}
      role="button"
      tabIndex={0}
      onClick={(event) => handleClick(event)}
      {...rest}
    >
      <figure className="honeycomb-image" {...rest}>
        <img
          className="img-fluid"
          src={src || defaultAvatar}
          alt={altImage}
          title={titleImage}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null
            currentTarget.src = defaultAvatar
          }}
        />
        <div className="honeycomb" style={{ backgroundColor: borderColor }}></div>
        {onClick && <button className="upload-image"><i className="fa-solid fa-camera"></i></button>}
      </figure>
    </div>
  )
}
