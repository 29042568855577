import Img1 from '@assets/images/png/onboarding/img-1.png'
import Img2 from '@assets/images/png/onboarding/img-2.png'
import Img3 from '@assets/images/png/onboarding/img-3.png'
import { motion } from 'framer-motion'
import React, { PropsWithChildren, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../button/button.component'
import './on-boarding.component.scoped.scss'

interface Props extends PropsWithChildren<React.HTMLAttributes<HTMLDivElement>> {
  show?: boolean
  onClose?: (event: any) => any
}

interface CircleProps extends PropsWithChildren<React.HTMLAttributes<HTMLDivElement>> {
  filled?: boolean
  borderColor?: string
  onClick?: (event: any) => any

}

const Circle = ({ filled, borderColor, onClick }: CircleProps) => {

  const handleClick = (event: any) => {
    if (onClick && typeof onClick === 'function') {
      onClick(event)
    }
  }

  return <button
    onClick={handleClick}
    className="circle"
    style={{ backgroundColor: filled ? borderColor : 'transparent', borderColor }}
  />
}

const steps = [
  {
    img: Img1,
    content: [
      'content.create',
      'content.sell',
      'content.collect',
      'content.discover'
    ],
    text: 'text.exploreNFT',
    filledCircleIndex: 0,
    color: 'var(--secondary_color)',
    textColor: 'var(--white-html)'
  },
  {
    img: Img2,
    content: ['content.startGallery'],
    text: 'text.showNFTs',
    filledCircleIndex: 1,
    color: 'var(--primary_color)',
    textColor: 'var(--secondary_color)'
  },
  {
    img: Img3,
    content: ['content.artLife'],
    text: 'text.welcome',
    filledCircleIndex: 2,
    color: 'var(--secondary_color)',
    textColor: 'var(--white-html)'
  }
]

export default ({ show, onClose }: Props) => {
  const [currentStep, setCurrentStep] = useState(0)
  const [t] = useTranslation('onboarding')

  if (!show) return null

  const handleClose = (event: any) => {
    if (show && typeof onClose === 'function') {
      onClose(event)
    }
  }

  const step = steps[currentStep]

  return (
    <div className="onboarding">
      <div className={`step step-${currentStep}`}>
        <motion.img
          key={step.img} // Adicione a propriedade key com o valor da imagem
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
          src={step.img}
          alt=""
        />
        <div className={`step-${currentStep}-wrapper-content`}>
          {step.content.map((line, i) => <span key={i}>{t(line)}</span>)}
        </div>
        <span>{t(step.text)}</span>
        <div className="button-wrapper">
          <div className="circles">
            {steps.map((_, i) =>
              <Circle
                onClick={() => setCurrentStep(i)}
                key={i}
                filled={i === step.filledCircleIndex}
                borderColor={step.color}
              />
            )}
          </div>

        </div>
        <Button
          borderColor={step.color}
          className='opacity button-height'
          textColor={step.textColor}
          color={step.color}
          onClick={currentStep === steps.length - 1 ? handleClose : () => setCurrentStep(currentStep + 1)}>
          {t('continue')}
        </Button>
      </div>
      <div className="step-counter">
      </div>
    </div>
  )
}