import InfinityScroll from '@/shared/components/infinity-scroll/infinity-scroll.component'
import LoaderComponent from '@/shared/components/loader/loader.component'
import NftFeedComponent from '@/shared/components/nft-feed/nft-feed.component'
import { Nft } from '@/shared/interfaces/nft.interface'
import NftService from '@/shared/services/nft.service'
import { HTMLAttributes, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import './feed.page.scoped.scss'

interface Props extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {

}

export default ({ className = '', ...rest }: Props) => {
  const [loading, setLoading] = useState(true)
  const [loadingList, setLoadingList] = useState(false)
  const [hasMore, setHasMore] = useState(true)
  const [page, setPage] = useState<number>(1)
  const [feedList, setFeedList] = useState<Nft[]>([])
  const nftService = useMemo(() => new NftService(), [])
  const PER_PAGE = 15
  const [tGlobal] = useTranslation()
  const navigate = useNavigate()

  const loadFeed = useCallback(async () => {
    if (!loadingList) {
      try {
        setLoadingList(!loading)
        const feedResponse = await nftService.getFeed(page, PER_PAGE)
        if (!feedResponse?.length && page === 1) {
          navigate('/search')
        }
        if (feedResponse?.length < PER_PAGE) {
          setHasMore(false)
        }
        setFeedList((f) => [...f, ...feedResponse])
      } catch (err) {
        // handle error
      } finally {
        setLoadingList(false)
        setLoading(false)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, nftService])

  const onScroll = useCallback(async () => {
    setPage((p) => p + 1)
  }, [])

  useEffect(() => {
    loadFeed()
  }, [page, loadFeed])

  if (loading) {
    return <div className={`feed-page my-3 container loader-wrapper ${className}`}>
      <LoaderComponent size='big' />
    </div>
  }

  if (!feedList.length && !loading) {
    return <div className={`feed-page ${className}`}>
      <h5 className='text-center my-4'>
        {tGlobal('empty')}
      </h5>
    </div>
  }

  return <div className={`feed-page ${className}`}>
    <InfinityScroll loading={loadingList} onLoadMore={onScroll} hasMore={hasMore}>
      <ul className='list-feed'>
        {feedList.map((nft, i) =>
          <li key={`${nft.id}-${i}`}>
            <NftFeedComponent nft={nft} />          
          </li>
        )}
      </ul>
    </InfinityScroll>
  </div>
}