import NftGridFeedComponent from '@/modules/user/components/nft-grid-feed/nft-grid-feed.component'
import InfinityScrollComponent from '@/shared/components/infinity-scroll/infinity-scroll.component'
import { Nft } from '@/shared/interfaces/nft.interface'
import { HTMLAttributes, PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import './feed-list-profile.component.scoped.scss'

interface Props extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {
  loading?: boolean
  loadingScroll?: boolean
  hasMore?: boolean
  hidden?: boolean
  onHidden?: (id: string) => void
  onScroll?: () => void
  list?: Nft[]
  customEmptyMessage?: string
}

export default ({ list = [], loading, loadingScroll, onScroll, hasMore, hidden, onHidden, customEmptyMessage = '', className = '', ...rest }: Props) => {
  const [t] = useTranslation('userProfilePage')

  return <div className={`feed-list-container ${className}`} {...rest}>
    {
      list?.length === 0 &&
      <div className='empty'>
        <p>
          {customEmptyMessage ? customEmptyMessage : t('empty')}
        </p>
      </div>
    }
    {
      list?.length > 0 &&
      <InfinityScrollComponent
        loading={loadingScroll}
        onLoadMore={onScroll}
        hasMore={hasMore}
      >
        <div className='feed-list'>
          {
            list.map((item: any) => (
              <NftGridFeedComponent nft={item} key={item.id} hidden={hidden} onHidden={onHidden} />
            ))
          }
        </div>
      </InfinityScrollComponent>
    }

  </div>
}