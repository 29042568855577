import ButtonComponent from '@/shared/components/button/button.component'
import { HTMLAttributes, PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import './no-wallets.component.scoped.scss'

interface Props extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {
 
}
 
export default ({ className = '', ...rest }: Props) => {
  const navigate = useNavigate()
  const [t] = useTranslation('userProfilePage')
  return <div className={`no-wallets ${className}`} {...rest}>
    <h5>
      {t('emptyWallets')}
    </h5>
    <ButtonComponent width='100%' id='empty-wallets-button' onClick={() => navigate('/wallets')}>
      {t('emptyWalletsButton')} <i className='fa-solid fa-wallet'></i>
    </ButtonComponent>
  </div>
}