
export default class DateHelper {
  timeSince(date: any): string {
    const currentDate = (new Date()).getTime()
    date = (new Date(date)).getTime()
    let seconds = Math.floor((currentDate - date) / 1000)
    let interval = seconds / 31536000
    if (interval > 1) {
      return Math.floor(interval) + 'y'
    }
    interval = seconds / 2592000
    if (interval > 1) {
      return Math.floor(interval) + 'm'
    }
    interval = seconds / 86400
    if (interval > 1) {
      return Math.floor(interval) + 'd'
    }
    interval = seconds / 3600
    if (interval > 1) {
      return Math.floor(interval) + 'h'
    }
    interval = seconds / 60
    if (interval > 1) {
      return Math.floor(interval) + 'm'
    }
    if (seconds < 0) {
      seconds = seconds * -1
    }
    return Math.floor(seconds) + 's'
  }
}