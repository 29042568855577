import Button from '@/shared/components/button/button.component'
import UserHelper from '@/shared/helpers/user.helper'
import { User } from '@/shared/interfaces/user.interface'
import userObservable from '@/shared/observables/user.observable'
import UserService from '@/shared/services/user.service'
import { HTMLAttributes, PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Subscription } from 'rxjs'
import HoneycombImageComponent from '../honeycomb-image/honeycomb-image.component'
import VerifiedIconComponent from '../verified-icon/verified-icon.component'
import './user-listed.component.scoped.scss'

interface Props extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {
  user: User
}

export default ({ user, className = '', ...rest }: Props) => {
  const onUserChangeSubscription = useRef<Subscription>()
  const [tGlobal] = useTranslation('userProfilePage')
  const userService = useMemo(() => new UserService(), [])
  const userHelper = useMemo(() => new UserHelper(), [])
  const [loggedUser, setLoggedUser] = useState<User | null>(null)
  const [isFollowing, setIsFollowing] = useState(false)
  const [loadingFollow, setLoadingFollow] = useState<any>('all')
  
  useEffect(() => {
    onUserChangeSubscription.current = userObservable.subscribe((u) => {
      return setLoggedUser(u)
    })

    return () => {
      onUserChangeSubscription.current?.unsubscribe()
    }
  }, [])

  useEffect(() => {
    checkFollow(user?.id)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, loggedUser])
  
  const doFollow = useCallback(async (id: any) => {
    if (user && id && !loadingFollow) {
      try {
        setLoadingFollow(id)
        await userService.putFollowById(id)
        setIsFollowing((old) => !old)
      } catch (error) {
        // TODO: handle error
      } finally {
        setLoadingFollow(false)
      }
    }
  }, [loadingFollow, user, userService])

  const checkFollow = useCallback(async (id: any) => {
    if (id) {
      const r = await userService.followUsersById(id)
      setIsFollowing(r?.follow)
      setLoadingFollow(false)
    }
  }, [userService])
  
  if (!user) {
    return <div />
  }

  return <div className={`user-listed ${className}`} {...rest}>
    <Link className='link-profile' to={`/profile/${user?.username}`}>
      <span className='wrapper-image'>
        <HoneycombImageComponent
          src={userHelper.getImageUrl(user?.id)}
        />
      </span>
      <span className='wrapper-text'>
        <span className='username'>@{user?.username} {user?.is_verified ? (<VerifiedIconComponent className='ml-1' style={{ fontSize: '90%' }} />) : null}</span>
        <span className='name'>{user?.name}</span>
      </span>
    </Link>
    {
      loggedUser?.id !== user?.id && loadingFollow !== 'all' && <div className='actions'>
        <Button
          size='sm'
          onClick={() => doFollow(user?.id)}
          loading={loadingFollow}
          hideChildrenOnLoading
        >
          {isFollowing ? tGlobal('unfollow') : tGlobal('follow')}
        </Button>
      </div>
    }
  </div>
}