import localForageHelper from '@helpers/localforage.helper'
import { USER_FIREBASE_SESSION, USER_KEY } from '@services/main.service'
const { REACT_APP_ENV_BUCKET_IMAGE_URL, REACT_APP_ENV_BUCKET_IMAGE_USERS, REACT_APP_ENV_BUCKET_IMAGE_PROFILE_NAME } = process.env
export default class UserHelper {
  async logout(navigate?: (path: string) => void, toLogin = true) {
    await localForageHelper.removeItem(USER_KEY)
    await localForageHelper.removeItem(USER_FIREBASE_SESSION)
    if (toLogin && navigate) {
      navigate('/login')
    }
  }

  getImageUrl(userId?: string | number | null) {
    if (!userId) {
      return ''
    }
    return `${REACT_APP_ENV_BUCKET_IMAGE_URL}${REACT_APP_ENV_BUCKET_IMAGE_USERS}/${userId}/${REACT_APP_ENV_BUCKET_IMAGE_PROFILE_NAME}`
  }
}