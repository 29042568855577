import Button from '@/shared/components/button/button.component'
import HoneycombImageComponent from '@/shared/components/honeycomb-image/honeycomb-image.component'
import InfinityScroll from '@/shared/components/infinity-scroll/infinity-scroll.component'
import DateHelper from '@/shared/helpers/date.helper'
import NftHelper from '@/shared/helpers/nft.helper'
import UserHelper from '@/shared/helpers/user.helper'
import { User } from '@/shared/interfaces/user.interface'
import onCountNotificationsChange from '@/shared/observables/count-notifications.observable'
import userObservable from '@/shared/observables/user.observable'
import NotificationService from '@/shared/services/notification.service'
import UserService from '@/shared/services/user.service'
import defaultImage from '@assets/images/jpg/image.jpg'
import Loader from '@components/loader/loader.component'
import { Notification } from '@interfaces/notification.interface'
import { HTMLAttributes, PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Subscription } from 'rxjs'
import './notifications-feed.component.scoped.scss'

interface Props extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {
  elementToScroll?: string
}

export default ({ className = '', style, elementToScroll = '' }: Props) => {
  const onUserChangeSubscription = useRef<Subscription>()
  const [t] = useTranslation('notifications')
  const [tGlobal] = useTranslation('userProfilePage')
  const notificationService = useMemo(() => new NotificationService(), [])
  const dateHelper = useMemo(() => new DateHelper(), [])
  const userService = useMemo(() => new UserService(), [])
  const userHelper = useMemo(() => new UserHelper(), [])
  const [user, setUser] = useState<User | null>()
  const nftHelper = useMemo(() => new NftHelper(), [])
  const [notifications, setNotifications] = useState<Notification[]>([])
  const [page, setPage] = useState<number>(1)
  const [hasMore, setHasMore] = useState<boolean>(true)
  const [loadingPagination, setLoadingPagination] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const navigate = useNavigate()
  const [isFollowing, setIsFollowing] = useState<Array<string | number>>([])
  const [loadingFollow, setLoadingFollow] = useState<any>(-1)
  const PER_PAGE = 20

  useEffect(() => {
    onUserChangeSubscription.current = userObservable.subscribe((u) => {
      return setUser(u)
    })

    return () => {
      onUserChangeSubscription.current?.unsubscribe()
    }
  }, [])

  useEffect(() => {
    if (((loading && page === 1) || !loading) && !loadingPagination && page > 0 && hasMore) {
      setLoadingPagination(!loading)
      notificationService.getNotifications(page, PER_PAGE).then(async (notifications) => {
        await Promise.all(notifications?.map(async (n) => {
          if (n.action === 'follows') {
            return await checkFollow(n?.metadata?.userId)
          }
          return n
        }))
        setNotifications((old) => [...old, ...notifications])
        if (!notifications.length) {
          setHasMore(false)
        }
        onCountNotificationsChange.next(0)
      }).finally(() => {
        setLoadingPagination(false)
        setLoading(false)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, notificationService])

  const handleNotification = async (notification: Notification) => {
    if (notification?.status !== 'read') {
      await notificationService.patchNotification(notification.id)
    }
    if (notification.action === 'follows') {
      navigate(`/profile/${notification?.metadata?.userInfo?.username}`)
    } else {
      navigate(`/nft/${notification?.metadata?.nftId}`)
    }
  }

  const doFollow = useCallback(async (id: any) => {
    if (user && id && loadingFollow === -1) {
      try {
        setLoadingFollow(id)
        await userService.putFollowById(id)
        const find = isFollowing.find((oldId) => oldId === id)
        if (!find) {
          setIsFollowing((old) => [...old, id])
        } else {
          setIsFollowing((old) => old.filter((oldId) => oldId !== id))
        }
      } catch (error) {
        // TODO: handle error
      } finally {
        setLoadingFollow(-1)
      }
    }
  }, [user, userService, isFollowing, loadingFollow])

  const checkFollow = useCallback(async (id: any) => {
    if (id) {
      const r = await userService.followUsersById(id)
      if (r?.follow) {
        setIsFollowing((old) => [...old, id])
      }
    }
  }, [userService])

  let content = (
    <div className="wrapper-loader text-center my-3">
      <Loader size="big" />
    </div>
  )

  if (!notifications?.length && !loading) {
    content = (
      <div className="no-notifications">
        <p>{t('emptyNotification')}</p>
      </div>
    )
  }

  if (notifications?.length && !loading) {
    content = (
      <InfinityScroll
        elementToScrollId={elementToScroll}
        loading={loadingPagination}
        onLoadMore={() => (!loadingPagination ? setPage(page + 1) : null)}
        endMessage={' '}
        hasMore={hasMore}
      >
        {notifications?.map((notification, index) => {
          return (
            <div className={`notification ${notification.status}`} key={`${notification.id}-${index}`}>
              <button className={`link-notification ${notification?.action === 'follows' ? 'minor' : ''}`} type="button" onClick={() => handleNotification(notification)}>
                <span className='wrapper-image'>
                  <HoneycombImageComponent
                    src={userHelper.getImageUrl(notification?.metadata?.userId)}
                  />
                </span>
                <span className='wrapper-message'>
                  <span className='message' dangerouslySetInnerHTML={{ __html: t(notification.action, { username: notification.metadata.userInfo.username, comment: notification.metadata?.commentInfo?.comment }) as string }} />
                  <span className='date'>{dateHelper.timeSince(notification?.createdAt)}</span>
                </span>
                {notification?.action !== 'follows' && nftHelper.parseNFTUrl(notification?.metadata?.nftInfo?.metadata?.image) ? (
                  <figure className='image'>
                    <img
                      className='img-fluid'
                      src={nftHelper.parseNFTUrl(notification?.metadata?.nftInfo?.metadata?.image)}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null
                        currentTarget.src = defaultImage
                      }}
                      alt={notification?.metadata?.nftInfo?.name}
                    />
                  </figure>
                ) : null}
              </button>
              {notification?.action === 'follows' && (
                <div className='actions'>
                  <Button
                    size='sm'
                    onClick={() => doFollow(notification?.metadata?.userId)}
                    loading={loadingFollow === notification?.metadata?.userId}
                    hideChildrenOnLoading
                  >
                    {isFollowing.includes(notification?.metadata?.userId) ? tGlobal('unfollow') : tGlobal('follow')}
                  </Button>
                </div>
              )}
            </div>
          )
        })}
      </InfinityScroll>
    )
  }

  return (
    <div className={`notifications-feed-wrapper ${className}`} style={style}>
      {content}
    </div>
  )
}