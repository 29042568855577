import NftGridFeedComponent from '@/modules/user/components/nft-grid-feed/nft-grid-feed.component'
import InfinityScrollComponent from '@/shared/components/infinity-scroll/infinity-scroll.component'
import LoaderComponent from '@/shared/components/loader/loader.component'
import { Nft } from '@/shared/interfaces/nft.interface'
import NftService from '@/shared/services/nft.service'
import { HTMLAttributes, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import './nft-search.page.scoped.scss'


interface Props extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {
  searchText?: string
}

export default ({ searchText = '', className = '', ...rest }: Props) => {
  const [tGlobal] = useTranslation()
  const nftService = useMemo(() => new NftService(), [])
  const [nfts, setNfts] = useState<Nft[]>([])
  const [nftsPage, setNftsPage] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingPage, setLoadingPage] = useState<boolean>(false)
  const [hasMore, setHasMore] = useState<boolean>(true)
  const PER_PAGE = 30

  const getNfts = useCallback(async () => {
    if (nftsPage < 1) {
      return
    }
    try {
      const nfts = await nftService.getNftSearch(
        searchText,
        nftsPage,
        PER_PAGE
      )
      setNfts((n) => nftsPage === 1 ? nfts?.data : n.concat(nfts?.data))
      setHasMore(nfts?.data?.length >= PER_PAGE)
      if (!nfts?.data?.length && !searchText && nftsPage === 1) {
        getNfts()
      }
    } catch (error) {
      // TODO: handle error
      setHasMore(false)
    } finally {
      setLoading(false)
      setLoadingPage(false)
    }
  }, [nftService, searchText, nftsPage])

  useEffect(() => {
    setLoading(true)
    setNftsPage(0)
    setHasMore(true)
    setTimeout(() => {
      setNftsPage(1)
    }, 500)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText])

  useEffect(() => {
    getNfts()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nftsPage])

  const onScroll = useCallback(() => {
    if (loadingPage || !hasMore || loading) {
      return
    }
    setLoadingPage(true)
    setNftsPage((p) => {
      const newP = p + 1
      setLoadingPage(newP !== 1)
      return newP
    })
  }, [loadingPage, hasMore, loading])

  if (loading) {
    return <div className={`nft-search my-5 text-center loader-wrapper ${className}`}>
    <LoaderComponent size='big' />
  </div>
  }

  if (nfts?.length === 0) {
    return <div className={`nft-search ${className}`}>
      <p className='empty'>
        {tGlobal('empty')}
      </p>
    </div>
  }

  return <div className={`nft-search ${className}`} {...rest}>
    <InfinityScrollComponent
      loading={loadingPage}
      onLoadMore={onScroll}
      endMessage={' '}
      hasMore={hasMore}
    >
      <div className='wrapper'>
        {
          nfts.map((item: any) => (
            <NftGridFeedComponent nft={item} key={item.id} />
          ))
        }
      </div>
    </InfinityScrollComponent>
  </div>
}