import React from 'react'
import { useTranslation } from 'react-i18next'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  color?: string
  customSize?: string
  size?: 'small' | 'bigger' | 'big' | ''
  borderSize?: string
}

export default ({ color = 'var(--tertiary_color)', customSize, size = '', borderSize, className = '', style, ...rest }: Props) => {
  const { t } = useTranslation()
  return (
    <div
      className={`loader ${size} ${className}`}
      role="status"
      style={{
        borderWidth: borderSize,
        height: customSize,
        width: customSize,
        borderColor: color,
        ...style
      }}
      {...rest}
    >
      <span className="visually-hidden">{t('loading')}</span>
    </div>
  )
}
