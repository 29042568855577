import localForageHelper from '@helpers/localforage.helper'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import './language-selector.scoped.scss'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  lang?: 'en' | 'pt' | 'es'
}

export default ({ lang = 'en', className = '' }: Props) => {
  const options = [
    {
      label: 'EN-US',
      value: 'en',
    },
    {
      label: 'ES-ES',
      value: 'es',
    },
    {
      label: 'PT-BR',
      value: 'pt',
    },
  ]
  const [value, setValue] = useState<string>('en')
  const { i18n } = useTranslation()

  const changeLang = useCallback(
    (event: any) => {
      const value = event?.target?.value || event
      document.documentElement.setAttribute('lang', value)
      setValue(value)
      localForageHelper.setItem('language', value)
      if (value !== i18n.language) {
        i18n.changeLanguage(value)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const parseFlagByLang = (lang: string) => {
    switch (lang) {
      case 'pt':
        return 'br'
      case 'es':
        return 'es'
      case 'en':
        return 'us'
      default:
        return 'us'
    }
  }

  useEffect(() => {
    if (lang !== value) {
      changeLang(lang)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang])

  useEffect(() => {
    localForageHelper.getItem('language').then((language: any) => {
      changeLang(language || navigator.language.split('-')[0] || 'en')
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <div className={`wrapper-language-selector ${className}`}>
      <span className={`fi fi-${parseFlagByLang(value)}`} />
      <select className="language-selector" value={value} onChange={changeLang} name="language-selector" id="language-selector">
        {options.map((option) => (
          <option key={option?.value} value={option?.value}>
            {option?.label}
          </option>
        ))}
      </select>
    </div>
  )
}
