import LoaderComponent from '@/shared/components/loader/loader.component'
import NftFeedComponent from '@/shared/components/nft-feed/nft-feed.component'
import { Nft } from '@/shared/interfaces/nft.interface'
import NftService from '@/shared/services/nft.service'
import { HTMLAttributes, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useNavigate, useParams } from 'react-router'
import './nft.page.scoped.scss'

interface Props extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {

}

export default ({ className = '', ...rest }: Props) => {
  const [t] = useTranslation('nftPage')
  const [loading, setLoading] = useState(true)
  const [nft, setNft] = useState<Nft>()
  const { id } = useParams<{ id: string }>()
  const nftService = useMemo(() => new NftService(), [])
  const navigate = useNavigate()

  const to404 = useCallback(() => {
    return navigate('/error/not-found')
  }, [navigate])

  useEffect(() => {
    const loadNft = async () => {
      try {
        const nftResponse = await nftService.getNftById(id)
        setNft(nftResponse)
      } catch (err) {
        to404()
      } finally {
        setLoading(false)
      }
    }
    if (id) {
      loadNft()
    }
    if (!id) {
      to404()
    }
  }, [id, nftService, to404])

  if (loading) {
    return <div className={`nft-page py-3 container loader-wrapper ${className}`}>
      <LoaderComponent size='big' />
    </div>
  }

  if (!nft) {
    return <Navigate to='/error/not-found' />
  }

  return <div className={`nft-page ${className}`} {...rest}>
    <NftFeedComponent
      nft={nft}
      commentsPerPage={10}
      showFullyDescription={true}
    />
  </div>
}