import Loader from '@components/loader/loader.component'
import { PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import './infinity-scroll.component.scoped.scss'

interface Props extends PropsWithChildren<React.HTMLAttributes<HTMLDivElement>> {
  elementToScrollId?: string
  endMessage?: string
  hasMore?: boolean
  loading?: boolean
  onLoadMore?: () => any
}

export default ({
  className = '',
  elementToScrollId = '',
  endMessage = '',
  hasMore = false,
  children,
  onLoadMore,
  loading = false,
}: Props) => {
  const [t] = useTranslation()
  const scroll = useRef<any>()
  const [isVisible, setIsVisible] = useState(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const observer = new IntersectionObserver(([entry]) => setIsVisible(!!entry.isIntersecting))

  const callLoadMore = useCallback(async () => {
    if (onLoadMore && typeof onLoadMore === 'function' && !loading) {
      await onLoadMore()
    }
  }, [onLoadMore, loading])

  const onScroll = useCallback(async () => {
    if (!loading && hasMore) {
      if (elementToScrollId) {
        const element = document.getElementById(elementToScrollId)
        if (element) {
          const allScroll = element.scrollTop + element.clientHeight + 50
          if (allScroll >= element.scrollHeight) {
            await callLoadMore()
          }
        }
      } else {
        if (window.innerHeight + window.scrollY >= scroll.current?.offsetHeight + scroll.current?.offsetTop) {
          await callLoadMore()
        }
      }
    }
  }, [callLoadMore, elementToScrollId, hasMore, loading])

  useEffect(() => {
    let element: any = window
    if (isVisible) {
      if (elementToScrollId) {
        element = document.getElementById(elementToScrollId)
      }
      if (!elementToScrollId) {
        element = window
      }
      element.addEventListener('scroll', onScroll)
    }

    return () => {
      element?.removeEventListener('scroll', onScroll)
    }
  }, [elementToScrollId, isVisible, onScroll])

  useEffect(() => {
    observer.observe(scroll.current)
    return () => {
      observer.disconnect()
    }
  }, [observer])

  return (
    <div className="wrapper-scroll">
      <div className={`scroll ${className}`} ref={scroll}>
        {children}
      </div>
      {loading ? (
        <div className="wrapper-loader">
          <Loader size="small" />
        </div>
      ) : null}
      {!hasMore && endMessage !== ' ' ? <p className="end-message">{endMessage || t('endScroll')}</p> : null}
    </div>
  )
}
