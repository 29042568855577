import React, { PropsWithChildren, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ToastHelper from '../../helpers/toast.helper'
import './button-copy-text.component.scoped.scss'

interface Props extends PropsWithChildren<React.HTMLAttributes<HTMLDivElement>> {
  color?: string
  backgroundColor?: string
  noMargin?: boolean
  size?: 'sm' | 'lg' | 'xl' | '2xl' | ''
  content: any
  textColor?: string
  maxWidth?: string
  onlyButton?: boolean
}

export default ({
  content,
  textColor,
  maxWidth,
  className = '',
  color = 'var(--primary_color)',
  backgroundColor = 'var(primary_color)',
  noMargin = false,
  size = 'lg',
  style,
  children,
  onlyButton = false
}: Props) => {
  const toast = useMemo(() => new ToastHelper().toast, [])
  const [isCopied, setIsCopied] = useState(false)

  const [t] = useTranslation('buttonCopyText')

  const handleClick = useCallback(async () => {
    if (typeof content === 'object') {
      await navigator.clipboard.writeText(content?.props?.children)
    } else {
      await navigator.clipboard.writeText(content)
    }
    setIsCopied(true)
    setTimeout(() => {
      setIsCopied(false)
    }, 3000)
    toast.success(t('urlCopied'), {
      closeButton: true,
      className: 'full text-bold',
      position: `${window.innerWidth > 991 ? 'top' : 'bottom'} center`,
      notOverClick: true,
      immediately: true,
    })
  }, [content, t, toast])

  if (onlyButton) {
    return (
      <div className={`button-copy-text only-button ${className}`} style={{ maxWidth, backgroundColor, borderTopColor: backgroundColor, padding: noMargin ? 0 : '', ...style }}>
        <button
          className="icon-card"
          onClick={handleClick}
          style={{ backgroundColor }}
        >
          {isCopied ? (
            <i className={`fa-solid fa-check green icon-copy fa-${size}`} />
          ) : (
            <i className={`fa-solid fa-copy ${color} icon-copy fa-${size}`} />
          )}
          {children}
        </button>
      </div>
    )
  }

  return (
    <div
      className={`button-copy-text input ${className}`}
      style={{
        maxWidth,
        backgroundColor,
        borderTopColor: backgroundColor,
        padding: noMargin ? 0 : '',
        ...style,
      }}
    >
      <span className="button-copy" style={{ backgroundColor, padding: noMargin ? 0 : '', color: textColor }}>
        {content}
      </span>
      <button onClick={handleClick} className="icon-card" style={{ backgroundColor }}>
        {isCopied ? (
          <i className={`fa-solid fa-check green icon-copy fa-${size}`} />
        ) : (
          <i className={`fa-solid fa-copy ${color} icon-copy fa-${size}`} />
        )}
      </button>
      {children}
    </div>
  )
}
