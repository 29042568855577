import Button from '@/shared/components/button/button.component'
import Input from '@/shared/components/input/input.component'
import ToastHelper from '@/shared/helpers/toast.helper'
import { USER_FIREBASE_SESSION, USER_KEY } from '@/shared/services/main.service'
import UserService from '@/shared/services/user.service'
import { getAuth, signInWithEmailAndPassword } from '@firebase/auth'
import localForageHelper from '@helpers/localforage.helper'
import { HTMLAttributes, PropsWithChildren, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import './login.page.scoped.scss'

interface Props extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {

}

export default ({ className = '', ...rest }: Props) => {
  const auth = getAuth()
  const userService = useMemo(() => new UserService(), [])
  const toast = new ToastHelper().toast
  const [t] = useTranslation('loginPage')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const [validate, setValidate] = useState<{
    email: boolean
    password: boolean
  }>({
    email: false,
    password: false
  })
  const validErrors = useMemo(() => [
    'auth/wrong-password',
    'auth/invalid-email',
    'auth/user-not-found',
    'auth/user-disabled'
  ], [])

  const handleErrors = useCallback((error = 'unexpectedError') => {
    if (validErrors.includes(error)) {
      return t(`errors.${error}`)
    }
    return t('errors.unexpectedError')
  }, [t, validErrors])

  const doLogin = useCallback( async (e: any) => {
    e.preventDefault()
    if (!loading) {
      setLoading(true)
      try {
        const result: any = await signInWithEmailAndPassword(auth, email, password)
        const accessToken = result?.user?.accessToken
        const refreshToken = result?.user?.refreshToken
    
        await localForageHelper.setItem(USER_FIREBASE_SESSION, { ...result })
        await localForageHelper.setItem(USER_KEY, { accessToken, refreshToken })

        const userInfo = await userService.getUserInfoByAuth()
        await localForageHelper.setItem(USER_KEY, { accessToken, refreshToken, userInfo })
        const followLink = await localForageHelper.getItem('follow_link')
        if (followLink) {
          await localForageHelper.removeItem('follow_link')
          navigate(followLink)
          return
        }
        navigate('/feed')
      } catch (error: any) {
        toast.error(handleErrors(error?.message?.match(/\(([^)]+)\)/)[1]), {
          closeButton: true,
          className: 'full',
          position: `${window.innerWidth > 991 ? 'top' : 'bottom'} center`,
          duration: 3600000,
          notOverClick: true,
          immediately: true,
        })
      } finally {
        setLoading(false)
      }
    }
  }, [loading, auth, email, password, userService, toast, handleErrors, navigate])

  return <div className={`login-page box mb-0 ${className}`} {...rest}>
    <h4 className='mb-0 mt-3'>
      {t('title')}
    </h4>
    <form onSubmit={doLogin}>
        <Input
          name='login-email'
          id='login-email'
          type='email'
          label={t('email') as string}
          placeholder={t('email') as string}
          value={email}
          readOnly={loading}
          onChange={value => setEmail(value)}
          onValidate={e => setValidate({ ...validate, email: e })}
          required
        />
        <Input
          name='login-password'
          id='login-password'
          type='password'
          label={t('password') as string}
          placeholder={t('password') as string}
          value={password}
          readOnly={loading}
          onChange={value => setPassword(value)}
          onValidate={e => setValidate({ ...validate, password: e })}
          required
          showPasswordEye
        />
        <Button
          type='submit'
          disabled={!validate.email || !validate.password}
          loading={loading}
          size='lg'
          id='button-login'
          width='100%'
        >
          {t('submit')}
        </Button>
    </form>
    <footer className='mb-3'>
      <p className='forgot-password'>
        <Link to='/login/forgot-password'>{t('passwordForget')}</Link>
      </p>
      <p>
        {t('dontHaveAccount')} <Link to={{
          pathname: '/login/register',
          search: searchParams.toString()
        }}>{t('register')}</Link>
      </p>
    </footer>
  </div>
}