import HoneycombImageComponent from '@/shared/components/honeycomb-image/honeycomb-image.component'
import VerifiedIconComponent from '@/shared/components/verified-icon/verified-icon.component'
import DateHelper from '@/shared/helpers/date.helper'
import localforage from '@/shared/helpers/localforage.helper'
import UserHelper from '@/shared/helpers/user.helper'
import { Comment } from '@/shared/interfaces/comment.interface'
import { Nft } from '@/shared/interfaces/nft.interface'
import { User } from '@/shared/interfaces/user.interface'
import CommentsService from '@/shared/services/comments.service'
import { USER_KEY } from '@/shared/services/main.service'
import UserService from '@/shared/services/user.service'
import { HTMLAttributes, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import './comment.component.scoped.scss'

interface Props extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {
  comment: Comment
  nft: Nft
  onDeleteComment?: (commentId: string | number) => any
}

export default ({ comment, nft, onDeleteComment, className = '', ...rest }: Props) => {
  const [tGlobal] = useTranslation()
  const userHelper = useMemo(() => new UserHelper(), [])
  const commentsService = useMemo(() => new CommentsService(), [])
  const dateHelper = useMemo(() => new DateHelper(), [])
  const userService = useMemo(() => new UserService(), [])
  const [loggedUser, setLoggedUser] = useState<User | null>(null)
  const [isVerified, setIsVerified] = useState<boolean>(false)
  const [liked, setLiked] = useState<boolean>(comment?.liked)
  const [countLikes, setCountLikes] = useState<number>(comment?.likes || 0)

  const deleteComment = useCallback(async (commentId: string | number) => {
    try {
      await commentsService.deleteCommentsById(commentId)
      if (onDeleteComment && typeof onDeleteComment === 'function') {
        onDeleteComment(commentId)
      }
    } catch (err) {
      // TODO:logs
    }
  }, [commentsService, onDeleteComment])

  const likeComment = useCallback(async (commentId: string | number) => {
    try {
      await commentsService.patchLikeCommentById(commentId)
      setLiked((l) => {
        if (l) {
          setCountLikes((c) => c - 1)
          return false
        }
        setCountLikes((c) => c + 1)
        return true
      })
    } catch (err) {
      // TODO: logs
    }
  }, [commentsService])

  useEffect(() => {
    localforage.getItem<any>(USER_KEY).then((u) => {
      if (u?.userInfo) {
        setLoggedUser(u?.userInfo)
      }
    })
  }, [])

  useEffect(() => {
    userService.getUserVerification(comment?.username)
      .then((r) => {
        setIsVerified(r)
      })
      .catch((err) => {
        // TODO:
      })
  }, [comment?.username, userService])

  if (!comment) {
    return null
  }

  return <div className={`comment ${className}`} {...rest}>
  <Link to={comment?.username ? `/profile/${comment?.username}` : ''} className='image-container'>
    <HoneycombImageComponent
      src={userHelper.getImageUrl(comment?.username ? comment?.userId : null)}
    />
  </Link>
  <span className={`comment-body ${loggedUser?.id === comment?.userId || nft.ownerId === loggedUser?.id ? 'can-delete' : ''}`}>
    {comment?.username ?
      <Link to={`/profile/${comment?.username}`} className='username'>
        {comment?.username}
        {isVerified ? (<VerifiedIconComponent className='ml-1' style={{ fontSize: '90%' }} />) : null}
      </Link> :
      <span className='username'>{tGlobal('notUser')}</span>
    }
    <span className='text'>{comment?.comment}</span>
    {loggedUser?.id === comment?.userId || nft.ownerId === loggedUser?.id ?
      <button className='delete action' onClick={() => deleteComment(comment.id)}>
        <i className="fa-solid fa-trash"></i>
      </button>
    : null}
    <button className='like action' onClick={() => likeComment(comment.id)}>
      <i className={`${liked ? 'fa-solid' : 'fa-regular'} fa-heart`} style={{ 
        color: liked ? 'var(--primary_color)' : ''
        }}></i>
    </button>
    <footer>
      {countLikes > 0 ? <span className='likes'>{countLikes} {tGlobal('likes')}</span> : null}
      <span className='date'>{dateHelper.timeSince(comment?.created_at)}</span>
    </footer>
  </span>
</div>
}