import Button from '@/shared/components/button/button.component'
import HoneycombImageComponent from '@/shared/components/honeycomb-image/honeycomb-image.component'
import LoaderComponent from '@/shared/components/loader/loader.component'
import Tabs from '@/shared/components/tabs/tabs.component'
import VerifiedIconComponent from '@/shared/components/verified-icon/verified-icon.component'
import localforage from '@/shared/helpers/localforage.helper'
import { User } from '@/shared/interfaces/user.interface'
import { UserWallet } from '@/shared/interfaces/wallets.interface'
import { USER_KEY } from '@/shared/services/main.service'
import UserService from '@/shared/services/user.service'
import WalletService from '@/shared/services/wallet.service'
import { HTMLAttributes, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import ModalFollowersListComponent from '../../components/modal-followers-list/modal-followers-list.component'
import NoWalletsComponent from './components/no-wallets/no-wallets.component'
import HiddenNftPage from './pages/hidden-nft/hidden-nft.page'
import PublicationsNftPage from './pages/publications-nft/publications-nft.page'
import SavesNftPage from './pages/saves-nft/saves-nft.page'
import './user-profile.page.scoped.scss'
import './user-profile.page.scss'

interface Props extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {

}

export default ({ className = '', ...rest }: Props) => {
  const [t] = useTranslation('userProfilePage')
  const [openFollowersModal, setOpenFollowersModal] = useState(false)
  const [openFollowingModal, setOpenFollowingModal] = useState(false)
  const userService = useMemo(() => new UserService(), [])
  const [loggedUser, setLoggedUser] = useState<User | null>(null)
  const [user, setUser] = useState<User | null>(null)
  const [metrics, setMetrics] = useState<any>(null)
  const [wallets, setWallets] = useState<UserWallet[]>([])
  const [loggedUserHasWallets, setLoggedUserHasWallets] = useState<boolean>(true)
  const walletsService = useMemo(() => new WalletService(), [])
  const [loading, setLoading] = useState(false)
  const [hiddenNft, setHiddenNft] = useState(false)
  const [isFollowing, setIsFollowing] = useState(false)
  const [isBlocked, setIsBlocked] = useState(false)
  const [loadingFollow, setLoadingFollow] = useState(true)
  const [loadingBlock, setLoadingBlock] = useState(true)
  const { username } = useParams()
  const [activeNav, setActiveNav] = useState('publications')
  const navigate = useNavigate()
  const metricsList = [
    {
      name: 'nft'
    },
    {
      name: 'followers',
      onClick: () => setOpenFollowersModal(true)
    },
    {
      name: 'following',
      onClick: () => setOpenFollowingModal(true)
    }
  ]
  const navItens = [
    {
      name: 'publications',
      icon: 'fa-grid-2'
    },
    {
      name: 'hidden',
      icon: 'fa-eye-slash'
    },
    {
      name: 'saves',
      icon: 'fa-bookmark'
    }
  ]

  const to404 = () => {
    navigate('/error/not-found')
  }

  const loadUser = useCallback(async (u: string, loggedUserLocal: User) => {
    try {
      setLoading(true)
      const [user, metrics] = await Promise.all([
        userService.getUserInfoByUsername(u),
        userService.getUserMetricsByUsername(u)
      ])
      setUser(user)
      setMetrics(metrics)
      if (loggedUserLocal?.id === user?.id) {
        const wallets = await walletsService.getUserWallets()
        setWallets(wallets)
        setLoggedUserHasWallets(!!wallets?.length)
      }
    } catch (error) {
      to404()
    } finally {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userService, loggedUser])

  useEffect(() => {
    localforage.getItem<any>(USER_KEY).then((u) => {
      if (u?.userInfo) {
        setLoggedUser(u?.userInfo)
        if (username && username !== user?.username) {
          loadUser(username, u?.userInfo)
        } else if (!username) {
          to404()
        }
      }
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username])

  useEffect(() => {
    setHiddenNft(false)
  }, [activeNav])

  useEffect(() => {
    if (loggedUser?.id && user?.id && loadingFollow) {
      userService.followUsersById(user?.id).then((r) => {
        setIsFollowing(r?.follow)
      }).finally(() => setLoadingFollow(false))
    }
  }, [loggedUser, user, userService, loadingFollow])

  useEffect(() => {
    if (loggedUser?.id && user?.id && loadingBlock) {
      userService.isBlockUser(user?.id).then((r) => {
        setIsBlocked(r?.blocked)
      }).finally(() => setLoadingBlock(false))
    }
  }, [loggedUser, user, userService, loadingBlock])

  const doFollow = useCallback(async () => {
    if (user) {
      userService.putFollowById(user?.id).then((r) => {
        setMetrics({
          ...metrics,
          followers: isFollowing ? metrics?.followers - 1 : metrics?.followers + 1
        })
        setIsFollowing(!isFollowing)
      })
    }
  }, [isFollowing, user, userService, metrics])

  const doBlock = useCallback(async () => {
    if (user) {
      userService.toggleBlockUser(user?.id).then((r) => {
        setIsBlocked(!isBlocked)
      })
    }
  }, [isBlocked, user, userService])

  const onHidden = useCallback(async () => {
    setHiddenNft(false)
  }, [])

  if (loading || !user?.id) {
    return (
      <div className={`profile-page box mb-0 loading ${className}`} {...rest}>
        <LoaderComponent size='bigger' />
      </div>
    )
  }

  return <div className={`profile-page box mb-0 ${className}`} {...rest}>
    <div className='grid head'>
      <h4>
        @{user?.username}
        {user?.is_verified ? (<VerifiedIconComponent className='ml-1' style={{ fontSize: '90%' }} />) : null}
      </h4>
      <div className='wrapper-pic' style={{
        backgroundImage: `url("https://storage.googleapis.com/wall-images/users/${user?.id}/cover.jpg")`
      }}>
        <HoneycombImageComponent src={`https://storage.googleapis.com/wall-images/users/${user?.id}/profile.jpg`} />
      </div>
      <div className='actions'>
        {
          (user?.id === loggedUser?.id && activeNav === 'publications') &&
          <Button
            size='sm'
            onClick={() => setHiddenNft(!hiddenNft)}
          >
            {hiddenNft ? t('cancelHiddenNft') : t('hiddenNft')}
          </Button>
        }
        {
          (user?.id === loggedUser?.id && activeNav === 'hidden') &&
          <Button
            size='sm'
            onClick={() => setHiddenNft(!hiddenNft)}
          >
            {hiddenNft ? t('cancelUnHiddenNft') : t('unHiddenNft')}
          </Button>
        }
        {
          user?.id === loggedUser?.id &&
          <Button
            size='sm'
            onClick={() => navigate('/profile-edit')}
          >
            {t('edit')}
          </Button>
        }
        {
          user?.id !== loggedUser?.id &&
          <Button
            size='sm'
            onClick={() => doFollow()}
            loading={loadingFollow}
            hideChildrenOnLoading={true}
          >
            {isFollowing ? t('unfollow') : t('follow')}
          </Button>
        }
        {
          user?.id !== loggedUser?.id &&
          <Button
            size='sm'
            onClick={() => doBlock()}
            loading={loadingBlock}
            hideChildrenOnLoading={true}
          >
            {isBlocked ? t('unblock') : t('block')}
          </Button>
        }
      </div>
      <div className='metrics'>
        {
          metricsList.map((item) => {
            if (item?.onClick && typeof item?.onClick === 'function') {
              return <div
                key={item.name}
                onClick={() => item?.onClick()}
                role='button'
                tabIndex={0}
                className='clickable'
              >
                <span className='value'>
                  {metrics?.[item.name]}
                </span>
                <span className='label'>
                  {t(item.name)}
                </span>
              </div>
            }
            return <div
              key={item.name}
            >
              <span className='value'>
                {metrics?.[item.name]}
              </span>
              <span className='label'>
                {t(item.name)}
              </span>
            </div>
          })
        }
      </div>
      <div className='info'>
        <h6 className='mb-1'>
          {user?.name}
        </h6>
        <p>
          {user?.bio}
        </p>
        {
          user?.link?.length ? (
            <a className='link-bio' href={user?.link} target='_blank' rel='noreferrer'>
              {user?.link}
            </a>
          ) : null
        }
      </div>
    </div>
    <div className='grid-body'>
      <Tabs defaultActiveIndex={0} lineStyle className='nav' noContent>
        {
          navItens.filter((i) => {
            if ((i.name === 'hidden' || i.name === 'saves') && user?.id !== loggedUser?.id) {
              return false
            }
            return true
          }).map((item) => (
            <Tabs.Tab
              onClick={(e) => setActiveNav(item.name)}
              key={item?.name}
              className={`${activeNav === item?.name ? 'active' : ''}`}
              title={(
                <div className='tab-item'>
                  <i className={`fa-regular ${item?.icon}`} />
                  <span>
                    {t(item?.name)}
                  </span>
                </div>
              )}
            ></Tabs.Tab>
          ))
        }
      </Tabs>
      <div className='content'>
        {
          activeNav === 'publications' && 
          (<>
            {
              !loggedUserHasWallets? <NoWalletsComponent /> : 
              <PublicationsNftPage
                user={user}
                loggedUser={loggedUser}
                hidden={hiddenNft}
                onHidden={(t: string) => onHidden()}
              />
            }
          </>)
        }
        {
          activeNav === 'hidden' &&
          (<>
            {
              !loggedUserHasWallets? <NoWalletsComponent /> : 
              <HiddenNftPage
                user={user}
                loggedUser={loggedUser}
                hidden={hiddenNft}
                onHidden={(t: string) => onHidden()}
              />
            }
          </>)
        }
        {
          activeNav === 'saves' && 
          <SavesNftPage
            user={user}
            loggedUser={loggedUser}
          />
        }
      </div>
    </div>
    <ModalFollowersListComponent
      username={user?.username as string}
      type='followers'
      open={openFollowersModal}
      onCloseChange={() => setOpenFollowersModal(false)}
    />
    <ModalFollowersListComponent
      username={user?.username as string}
      type='following'
      open={openFollowingModal}
      onCloseChange={() => setOpenFollowingModal(false)}
    />
  </div>
}