import Button from '@/shared/components/button/button.component'
import Input from '@/shared/components/input/input.component'
import ToastHelper from '@/shared/helpers/toast.helper'
import UserHelper from '@/shared/helpers/user.helper'
import { User } from '@/shared/interfaces/user.interface'
import userObservable from '@/shared/observables/user.observable'
import UserService from '@/shared/services/user.service'
import { HTMLAttributes, PropsWithChildren, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { Subscription } from 'rxjs'
import './delete-account.page.scoped.scss'

interface Props extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {

}

export default ({ className = '', ...rest }: Props) => {
  const onUserChangeSubscription = useRef<Subscription>()
  const [t] = useTranslation('deleteAccountPage')
  const [loading, setLoading] = useState(false)
  const [user, setUser] = useState<User | null>(null)
  const navigate = useNavigate()
  const userService = useMemo(() => new UserService(), [])
  const userHelper = useMemo(() => new UserHelper(), [])
  const [errorMessage, setErrorMessage] = useState('')
  const [inputText, setInputText] = useState('')
  const toast = new ToastHelper().toast

  useEffect(() => {
    onUserChangeSubscription.current = userObservable.subscribe((u) => {
      return setUser(u)
    })

    return () => {
      onUserChangeSubscription.current?.unsubscribe()
    }
  }, [])

  const handleChange = (e: any) => {
    setInputText(e)
    setErrorMessage(user?.username !== e ? t('usernameError') as string : '')
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    if (!loading) {
      setLoading(true)
      try {
        await userService.deleteUser()
        toast.success(t('success'), {
          closeButton: true,
          className: 'full',
          position: `${window.innerWidth > 991 ? 'top' : 'bottom'} center`,
          duration: 3600000,
          notOverClick: true,
          immediately: true,
        })
        setTimeout(() => {
          userHelper.logout(navigate)
        })
      } catch (error) {
        toast.error(t('error'), {
          closeButton: true,
          className: 'full',
          position: `${window.innerWidth > 991 ? 'top' : 'bottom'} center`,
          duration: 3600000,
          notOverClick: true,
          immediately: true,
        })
      } finally {
        setLoading(false)
      }
    }
  }

  if (!user) {
    return null
  }

  return <div className={`delete-account-page box ${className}`} {...rest}>
    <header>
      <h5>{t('title')}</h5>
    </header>
    <form onSubmit={(e) => handleSubmit(e)}>
      <div className='content'>
        <p>
          {t('confirm')}
        </p>
        <Input
          label={t('confirmBox', { username: user?.username })}
          id='input-confirm-delete-account'
          name='input-confirm-delete-account'
          externalError={errorMessage}
          value={inputText}
          onChange={(e) => handleChange(e)}
          readOnly={loading}
        />
      </div>
      <footer>
        <Button
          size='sm'
          id='button-no-delete-account'
          onClick={() => navigate('/settings')}
          disabled={loading}
        >
          {t('no')}
        </Button>
        <Button
          size='sm'
          type='submit'
          id='button-delete-account'
          color='transparent'
          textColor='var(--text_color)'
          borderColor='transparent'
          disabled={!inputText || !!errorMessage}
          loading={loading}
        >
          {t('yes')}
        </Button>
      </footer>
    </form>
  </div>
}