import Tabs from '@/shared/components/tabs/tabs.component'
import { HTMLAttributes, PropsWithChildren, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import './invites.page.scoped.scss'
import './invites.page.scss'
import AvaliablePage from './pages/avaliable/avaliable.page'
import UsedPage from './pages/used/used.page'

interface Props extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {

}

export default ({ className = '', ...rest }: Props) => {
  const [t] = useTranslation('invitesPage')
  const [activeNav, setActiveNav] = useState<string>(window?.location?.pathname?.split('/')?.pop() || '')
  const location = useLocation()
  const navigate = useNavigate()
  const menu = [
    {
      title: t('avaliable'),
      path: '/invites/avaliable',
      name: 'avaliable',
      icon: 'fa-paper-plane-top',
    },
    {
      title: t('used'),
      path: '/invites/used',
      name: 'used',
      icon: 'fa-check-circle',
    }
  ]

  const onClickNav = (name: string) => {
    setActiveNav(name)
    navigate(`/invites/${name}`)
  }

  const OnRedirect = () => {
    setActiveNav('avaliable')
    return <Navigate to={{ pathname: '/invites/avaliable' }} />
  }

  const defaultPage = useCallback(() => {
    const finalPath = location?.pathname?.split('/')?.pop()
    switch (finalPath) {
      case 'used':
        return 1
      case 'avaliable':
        return 0
      default:
        return 0
    }
  }, [location])

  return <div className={`invites-page container box ${className}`} {...rest}>
    <h1>
      {t('title')}
    </h1>
    <Tabs tabsWidth defaultActiveIndex={defaultPage()} lineStyle className='nav' noContent>
      {
        menu.map((item) => (
        <Tabs.Tab
          onClick={(e) => onClickNav(item.name)}
          key={item?.name}
          className={`${activeNav === item?.name ? 'active' : ''}`}
          title={(
            <div className='tab-item'>
              <i className={`fa-regular ${item?.icon}`} />
              <span>
                {t(item?.name)}
              </span>
            </div>
          )}
        ></Tabs.Tab>
        ))
      }
    </Tabs>
    <Routes>
      <Route index element={<OnRedirect />} />
      <Route path='avaliable' element={<AvaliablePage />} />
      <Route path='used' element={<UsedPage />} />
      <Route path="*" element={<Navigate to={{ pathname: '/error/not-found' }} />} />
    </Routes>
  </div>
}