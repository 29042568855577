import Button from '@/shared/components/button/button.component'
import LoaderComponent from '@/shared/components/loader/loader.component'
import { Nft } from '@/shared/interfaces/nft.interface'
import { User } from '@/shared/interfaces/user.interface'
import NftService from '@/shared/services/nft.service'
import UserService from '@/shared/services/user.service'
import { HTMLAttributes, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import FeedListProfileComponent from '../../components/feed-list-profile/feed-list-profile.component'
import './hidden-nft.page.scoped.scss'

interface Props extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {
 hidden?: boolean
 onHidden?: (tabName: string) => void
 user: User | null
 loggedUser: User | null
}

export default ({ user, loggedUser, hidden = false, onHidden, className = '', ...rest }: Props) => {
  const [t] = useTranslation('userProfilePage')
  const userService = useMemo(() => new UserService(), [])
  const nftService = useMemo(() => new NftService(), [])
  const [hasMore, setHasMore] = useState(true)
  const [list, setList] = useState<Nft[]>([])
  const [loading, setLoading] = useState(true)
  const [loadingScroll, setLoadingScroll] = useState(false)
  const [requestInProcess, setRequestInProcess] = useState(false)
  const [page, setPage] = useState(1)
  const [selectedNfts, setSelectedNfts] = useState<string[]>([])

  const loadFeed = useCallback(async () => {
    try {
      setLoading(true)
      setRequestInProcess(true)
      const list = await userService.getHiddenNftListByUsername()
      setList(list?.data || [])
      setPage(1)
    } catch (error) {
      // TODO: handle error
    } finally {
      setLoading(false)
      setRequestInProcess(false)
    }
  }, [userService])

  useEffect(() => {
    if (!user && !requestInProcess) {
      return
    }
    loadFeed()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    setSelectedNfts([])
  }, [hidden])

  const onScroll = useCallback(async () => {
    if (!loadingScroll && hasMore) {
      try {
        setLoadingScroll(true)
        const r = await userService.getHiddenNftListByUsername(page + 1)
        setList((old) => old.concat(r?.data || []))
        setPage(page + 1)
        setHasMore(r?.data?.length > 0)
      } catch (error) {
      } finally {
        setLoadingScroll(false)
      }
    }
  }, [loadingScroll, hasMore, userService, page])

  const setHideNftIds = (id: string) => {
    if (!selectedNfts.includes(id)) {
      setSelectedNfts([...selectedNfts, id])
    } else {
      setSelectedNfts(selectedNfts.filter((str) => str !== id))
    }
  }

  const handleHidden = useCallback(async () => {
    if (onHidden && typeof onHidden === 'function') {
      onHidden('hidden')
    }
  }, [onHidden])

  const doHidden = useCallback(async () => {
    if (selectedNfts?.length && !loading) {
      setLoading(true)
      try {
        await nftService.putHidden({
          ids: selectedNfts,
          hidden: false
        })
        setSelectedNfts([])
        handleHidden()
        await loadFeed()
      } catch (error) {
        // TODO: handle error
      } finally {
        setLoading(false)
      }
    }
  }, [handleHidden, loadFeed, loading, nftService, selectedNfts])

  if (loading) {
    return <div className={`my-3 loading ${className}`} {...rest}>
      <LoaderComponent size='big' />
    </div>
  }

  return <>
    <FeedListProfileComponent
      onScroll={onScroll}
      list={list}
      hasMore={hasMore}
      loading={loading}
      hidden={hidden}
      onHidden={setHideNftIds}
      loadingScroll={loadingScroll}
      customEmptyMessage={t('emptyToShow') as string}
    />
    {hidden && <Button className='fixed-save-button' onClick={doHidden} disabled={!selectedNfts.length}>{t('saveNft')}</Button>}
  </>
}