import LanguageSelector from '@/shared/components/language-selector/language-selector'
import ToggleThemeComponent from '@/shared/components/toggle-theme/toggle-theme.component'
import { HTMLAttributes, PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import './general-settings.page.scoped.scss'

interface Props extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {

}

export default ({ className = '', ...rest }: Props) => {
  const [t] = useTranslation('generalConfigurationPage')
  const FORM_LINK = 'https://forms.gle/9aH2D97EArJ9yUfMA'
  const WALLBEE_SUPORT__EMAIL = 'hello@wallbee.io'



  function openHelpEmail() {
    window.location.href = `mailto:${WALLBEE_SUPORT__EMAIL}`
    }

  return <div className={`general-settings-page box ${className}`} {...rest}>
    <h5>{t('title')}</h5>
    <div className='wrapper-toggle'>
      <ToggleThemeComponent marginAutoToggle />
    </div>
    <div className='wrapper-lang'>
      <label className='mb-0'>
        {t('language')}
      </label>
      <LanguageSelector />
    </div>
    <div className='wrapper-link'>
      <a href={FORM_LINK} target='_blank'>
        {t('verify')}
      </a>
    </div>
    <div className='wrapper-link'>
      <a href={`mailto:${WALLBEE_SUPORT__EMAIL}`}>
        {t('help')}
      </a>
    </div>
    <div className='wrapper-delete'>
      <Link to='/delete'>
        {t('delete')} Wallbee
      </Link>
    </div>
  </div>
}