import Button from '@/shared/components/button/button.component'
import Input from '@/shared/components/input/input.component'
import ToastHelper from '@/shared/helpers/toast.helper'
import LoginService from '@/shared/services/login.service'
import UserService from '@/shared/services/user.service'
import { HTMLAttributes, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import './register.page.scoped.scss'

interface Props extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {

}

export default ({ className = '', ...rest }: Props) => {
  const [t] = useTranslation('registerPage')
  const toast = new ToastHelper().toast
  const loginService = useMemo(() => new LoginService(), [])
  const userService = useMemo(() => new UserService(), [])
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [register, setRegister] = useState({
    name: '',
    email: '',
    confirmEmail: '',
    password: '',
    confirmPassword: '',
    username: '',
  })
  const [validation, setValidation] = useState({
    name: false,
    email: false,
    confirmEmail: false,
    password: false,
    confirmPassword: true,
    username: false,
  })
  const [usernameInUse, setUsernameInUse] = useState(false)
  const [emailInUse, setEmailInUse] = useState(false)
  const [loadingCountUsername, setLoadingCountUsername] = useState(false)
  const [loadingCountEmail, setLoadingCountEmail] = useState(false)
  const [usernameTiping, setUsernameTiping] = useState(true)
  const [emailTiping, setEmailTiping] = useState(true)

  const validErrors = useMemo(() => [
    'INVITE_NOT_FOUND',
    'INVITE_IN_USE',
    'USERNAME_IN_USE',
    'EMAIL_IN_USE'
  ], [])

  useEffect(() => {
    if (register?.password?.length && register?.confirmPassword?.length) {
      setValidation((v) => {
        return {
          ...v,
          confirmPassword: register?.password === register?.confirmPassword
        }
      })
    }
  }, [register?.confirmPassword, register?.password])

  useEffect(() => {
    if (register?.email?.length && register?.confirmEmail?.length) {
      setValidation((v) => {
        return {
          ...v,
          confirmEmail: register?.email === register?.confirmEmail
        }
      })
    }
  }, [register?.confirmEmail, register?.email])

  useEffect(() => {
    if (register?.email?.length && !loadingCountEmail && !emailTiping) {
      setLoadingCountEmail(true)
      userService.countField('email', register?.email).then((r) => {
        setValidation((v) => {
          return {
            ...v,
            email: r?.count < 1
          }
        })
        setEmailInUse(r?.count > 0)
      })
      .finally(() => setLoadingCountEmail(false))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register?.email, userService, emailTiping])

  useEffect(() => {
    if (register?.username?.length && !loadingCountUsername && !usernameTiping) {
      setLoadingCountUsername(true)
      userService.countField('username', register?.username).then((r) => {
        setValidation((v) => {
          return {
            ...v,
            username: r?.count < 1
          }
        })
        setUsernameInUse(r?.count > 0)
      })
      .finally(() => setLoadingCountUsername(false))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register?.username, userService, usernameTiping])

  const validateAllValidation = () => {
    const { name, email, confirmEmail, password, confirmPassword, username } = validation
    return name && email && password && confirmPassword && username && confirmEmail && !usernameInUse && !emailInUse && register?.confirmPassword?.length
  }

  const handleErrors = useCallback((error = 'unexpectedError') => {
    if (validErrors.includes(error)) {
      return t(`errors.${error}`)
    }
    return t('errors.unexpectedError')
  }, [t, validErrors])

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!loading && validateAllValidation()) {
      setLoading(true)
      try {
        const invite = searchParams.get('inviteCode')
        const payload: any = {...register, shortcode: invite }
        delete payload.confirmPassword
        delete payload.confirmEmail
        const response = await loginService.resgisterUser(payload)
        if (response?.id) {
          toast.success(t('success'), {
            closeButton: true,
            className: 'full',
            position: `${window.innerWidth > 991 ? 'top' : 'bottom'} center`,
            notOverClick: true,
            immediately: true,
          })
          navigate('/login')
        }
      } catch (error: any) {
        let customError = error?.response.data.errorCode
        if (error?.response?.data?.message?.includes('shortcode')) {
          customError = 'INVITE_NOT_FOUND'
        }
        if (error?.response?.data?.stack?.includes('users.email')) {
          customError = 'EMAIL_IN_USE'
        }
        if ( error?.response?.data?.stack?.includes('users.username') ) {
          customError = 'USERNAME_IN_USE'
        }
        toast.error(handleErrors(customError), {
          closeButton: true,
          className: 'full',
          position: `${window.innerWidth > 991 ? 'top' : 'bottom'} center`,
          duration: 3600000,
          notOverClick: true,
          immediately: true,
        })
      } finally {
        setLoading(false)
      }
    }
  }

  return (<div className={`register-page box mb-0 ${className}`} {...rest}>
    <h4 className='my-3'>
      {t('title')}
    </h4>
    <p className='mb-5' dangerouslySetInnerHTML={{ __html: t('subtitle') as string}} />
    <form onSubmit={handleSubmit}>
      <Input
        id='name-register'
        name='name-register'
        placeholder={t('fields.name') as string}
        label={t('fields.name') as string}
        onChange={(value) => setRegister({ ...register, name: value })}
        onValidate={(value) => setValidation({ ...validation, name: value })}
        value={register.name}
      />
      <Input
        id='username-register'
        name='username-register'
        loading={loadingCountUsername}
        placeholder={t('fields.username') as string}
        label={t('fields.username') as string}
        onChange={(value) => setRegister({ ...register, username: value?.trim() })}
        onTimeout={(bool) => setUsernameTiping(!bool)}
        externalError={usernameInUse ? t('errors.USERNAME_IN_USE') : ''}
        value={register.username}
      />
      <Input
        id='email-register'
        name='email-register'
        type='email'
        loading={loadingCountEmail}
        placeholder={t('fields.email') as string}
        label={t('fields.email') as string}
        onChange={(value) => setRegister({ ...register, email: value?.trim() })}
        onTimeout={(bool) => setEmailTiping(!bool)}
        externalError={emailInUse ? t('errors.EMAIL_IN_USE') : ''}
        value={register.email}
      />
            <Input
        id='email-register-confirm'
        name='email-register-confirm'
        type='email'
        placeholder={t('fields.email') as string}
        label={t('fields.emailConfirm') as string}
        onChange={(value) => setRegister({ ...register, confirmEmail: value?.trim() })}
        externalError={register.email !== register.confirmEmail ? t('errors.EMAIL_CONFIRM') : ''}
        value={register.confirmEmail}
      />
      <Input
        id='password-register'
        name='password-register'
        type='password'
        label={t('fields.password') as string}
        placeholder={t('fields.password') as string}
        onChange={(value) => setRegister({ ...register, password: value })}
        onValidate={(value) => setValidation({ ...validation, password: value })}
        value={register.password}
      />
      <Input
        id='confirm-password-register'
        name='confirm-password-register'
        type='password'
        label={t('fields.confirmPassword') as string}
        placeholder={t('fields.confirmPassword') as string}
        onChange={(value) => setRegister({ ...register, confirmPassword: value })}
        externalError={!validation.confirmPassword ? t('errors.PASSWORD_NOT_MATCH') : ''}
        value={register.confirmPassword}
      />
      <Input
        id='invite-code-register'
        name='invite-code-register'
        label={t('fields.inviteCode') as string}
        placeholder={t('fields.inviteCode') as string}
        onChange={(value) => setSearchParams({ inviteCode: value })}
        value={searchParams.get('inviteCode') || ''}
      />
      <p className='terms' dangerouslySetInnerHTML={{ __html: t('terms') as string }} />
      <Button
        type='submit'
        disabled={!validateAllValidation()}
        loading={loading}
        size='lg'
        id='button-login'
        width='100%'
      >
        {t('submit')}
      </Button>
      <p className='login-link mb-3'>
        {t('footer')}{' '}
        <Link to='/login'>{t('footerLogin')}</Link>
      </p>
    </form>
  </div>)
}