import Button from '@/shared/components/button/button.component'
import Input from '@/shared/components/input/input.component'
import ToastHelper from '@/shared/helpers/toast.helper'
import { getAuth, sendPasswordResetEmail } from '@firebase/auth'
import { HTMLAttributes, PropsWithChildren, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import './forgot-password.page.scoped.scss'

interface Props extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {

}

export default ({ className = '', ...rest }: Props) => {
  const auth = getAuth()
  const toast = new ToastHelper().toast
  const [t] = useTranslation('resetPasswordPage')
  const [email, setEmail] = useState('')
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const [validate, setValidate] = useState<{
    email: boolean
    password: boolean
  }>({
    email: false,
    password: false
  })
  const validErrors = useMemo(() => [
    'auth/user-not-found'
  ], [])

  const handleErrors = useCallback((error = 'unexpectedError') => {
    if (validErrors.includes(error)) {
      return t(`errors.${error}`)
    }
    return t('errors.unexpectedError')
  }, [t, validErrors])

  const doSendReset = useCallback(async (e: any) => {
    e.preventDefault()
    if (!loading) {
      setLoading(true)
      try {
        await sendPasswordResetEmail(auth, email)
        toast.success(t('resetSend'), {
          closeButton: true,
          className: 'full',
          position: `${window.innerWidth > 991 ? 'top' : 'bottom'} center`,
          duration: 3600000,
          notOverClick: true,
          immediately: true,
        })
        navigate('/login')
      } catch (error: any) {
        toast.error(handleErrors(error?.message?.match(/\(([^)]+)\)/)[1]), {
          closeButton: true,
          className: 'full',
          position: `${window.innerWidth > 991 ? 'top' : 'bottom'} center`,
          duration: 3600000,
          notOverClick: true,
          immediately: true,
        })
      } finally {
        setLoading(false)
      }
    }
  }, [loading, auth, email, toast, handleErrors, navigate, t])

  return <div className={`forgot-password-page box mb-0 ${className}`} {...rest}>
    <h4 className='mb-0 mt-3'>
      {t('title')}
    </h4>
    <form onSubmit={doSendReset}>
      <Input
        name='login-email'
        id='login-email'
        type='email'
        placeholder={t('email') as string}
        value={email}
        readOnly={loading}
        onChange={value => setEmail(value)}
        onValidate={e => setValidate({ ...validate, email: e })}
        required
      />
      <Button
        type='submit'
        disabled={!validate.email}
        loading={loading}
        size='lg'
        id='button-login'
        width='100%'
      >
        {t('resetPassword')}
      </Button>
    </form>
    <footer className='mb-3'>
      <p className='forgot-password'>
        <Link to='/login'>{t('login')}</Link>
      </p>
      <p>
        {t('dontHaveAccount')} <Link to={{
          pathname: '/login/register',
          search: searchParams.toString()
        }}>{t('register')}</Link>
      </p>
    </footer>
  </div>
}